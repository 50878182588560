.report-page {
  // padding-top: 48px;

  .title {
    padding-top: 0 !important;
  }

  .top-content {
    display: flex;
    justify-content: space-between;

    .check-date {
      height: fit-content;
      width: 23%;
      min-width: 315px;
      margin-right: 24px;
      padding: 16px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.16);

      .title {
        color: #0E283F;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 0.5px solid #96A7C4;
        padding-bottom: 5px;
      }

      .date-range {
        margin: 20px 0px;
        padding: 16px;
        background-color: rgba($color: #F0F6FF, $alpha: 0.6);
        border-radius: 10px;

        .label {
          font-size: 16px;
          color: #96A7C4;
        }

        .calendar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;

          .rdt {
            width: 100%;
            padding-left: 12px;

            input {
              border: none;
              border-bottom: 1px solid #000;
              border-radius: 0;
              margin-top: 0;
            }
          }

          .react-select {
            width: 100%;
            // margin-top: 5px;
            margin-bottom: 0;

            .react-select__control {
              border: none;
              border-bottom: 1px solid #000;
              border-radius: 0;

              .react-select__value-container {
                padding-left: 0 !important;
              }

              .react-select__indicators {
                padding-right: 0 !important;
              }

              .react-select__placeholder {
                font-size: 18px;
              }
            }
          }
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        div {
          padding: 6px 12px;
          width: 48%;
          font-size: 20px;
        }
      }
    }

    .totals-by-employee {
      width: 73%;
      padding: 16px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.16);

      .title {
        color: #0E283F;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 0.5px solid #96A7C4;
        padding-bottom: 5px;
      }

      .employee-table {
        .headers {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
          padding: 12px 0px;
          color: #0E283F;

          div {
            &:first-child {
              width: 25%;
            }

            &:nth-child(2) {
              width: 25%;
            }

            &:nth-child(3) {
              width: 25%;
            }

            &:last-child {
              width: 25%;
            }
          }
        }

        .records {
          .record-mobile {
            display: none;
          }

          .record {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            color: #0E283F;
            margin: 6px 0px;

            div {
              &:first-child {
                width: 25%;
              }
  
              &:nth-child(2) {
                width: 25%;
              }
  
              &:nth-child(3) {
                width: 25%;
              }
  
              &:last-child {
                width: 25%;
              }
            }
          }
        }
      }
    } 
  }

  .bottom-content {
    margin-top: 48px;

    .payroll {
      padding: 16px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.16);

      .title {
        color: #0E283F;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 0.5px solid #96A7C4;
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;

        .btn-blue {
          padding: 6px 12px;
          font-size: 20px;
          font-weight: 500;
        }
      }

      .payroll-table {
        .headers {
          padding: 12px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            font-weight: bold;
            font-size: 18px;
            width: 16%;

            &:last-child {
              width: 6%;
            }
          }
        }

        .records {
          .remove-icon {
            background-color: #5A7288;
            width: 31px !important;
            height: 31px;
            display: flex;
            justify-content: center !important;
            align-items: center !important;
            color: #fff;
            border-radius: 5px;

            svg {
              transform: rotate(45deg );
              transition: all 0.3s ease-in-out;
            }

            &:hover {
              cursor: pointer;

              svg {
                transform: rotate(315deg);
              }
            }
          }

          .record {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            // margin-top: 16px;
            padding: 8px 0px;

            div {
              width: 16%;

              &:last-child {
                width: 6%;
              }
            }

            &:hover {
              background-color: rgba($color: #F0F6FF, $alpha: 0.6);
            }
          }

          .record-mobile {
            display: none;
          }
        } 
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .report-page {
    .top-content {
      display: block;
      .check-date {
        width: 100%;
        margin-bottom: 48px;
      }

      .totals-by-employee {
        width: 100%;
      }
    }

    .bottom-content {
      .payroll {
        .payroll-table {
          .headers {
            display: none;
          }
          
          .records {
            .record {
              display: none;
            }
  
            .record-mobile {
              display: block;
              font-size: 18px;
              background-color: rgba($color: #F0F6FF, $alpha: 0.6);
              padding: 6px 12px;
              margin: 12px 0px;

              div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 6px 0px;

                span {
                  &:first-child {
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .report-page {
    .top-content {
      .check-date {
        
      }

      .totals-by-employee {
        .employee-table {
          .headers {
            display: none;
          }

          .records {
            .record {
              display: none;
            }

            .record-mobile {
              display: block;
              background-color: rgba($color: #F0F6FF, $alpha: 0.6);
              padding: 6px 12px; 
              margin-bottom: 12px;
              margin-top: 12px;

              div {
                display: flex;
                justify-content: space-between;
                margin: 6px 0px;

                span {
                  &:first-child {
                    font-weight: bold;
                  }

                  &:last-child {
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }

    .bottom-content {
      .payroll {
        .records {
         
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .report-page {
    .bottom-content {
      .payroll {
        .records {
          
        }
      }
    }
  }
}