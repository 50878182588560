
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.input-form {
    position: relative;
    height: 44px;
    border-bottom: 1px solid #0E283F;

    &:hover {
        cursor: pointer;

        input {
            cursor: pointer;
        }
    }

    input {
        z-index: 1;
        width: 100%;
        height: 100%;
        border: none;
        background-color: #fff !important;

        &:focus {
            outline: none;
        }
    }

    .label {
        position: absolute;
        width: 80%;
        top: 12px;
        left: 0;
        font-size: 15px;
        z-index: 10;
        background-color: #fff;
        text-align: left;
        transition: all 0.2s ease-in-out;
    }

    .input-addon {
        width: 32px;
        height: 21px;
        position: absolute;
        right: 0;
        top: 12px;
    }
}

.has-error {
    border-bottom: 1px solid red;
}