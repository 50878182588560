.custom-btn {
    background-color: #ECF2FB;
    font-size: $font-minimum;
    color: $text-color-sixth;
    border-radius: 5px;
    // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-left: 15px;
    padding: 4px 8px;

    span {
        // margin-right: 10%;
    }

    &:hover {
        cursor: pointer;
    }
}

@media screen and (max-width: $breakpoint-first) {
    .custom-btn {
        font-size: $font-minimum;
        padding: 4px 6px;
    }
}
