.employee-expense-page {
  .page-content {
    max-width: 780px;
    width: 100%;
    box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.39);
    background-color: #fff;
    border-radius: 10px;
    margin: 0 auto;
    padding: 48px;
    color: #22395B;
    display: block;

    .input {
      .label {
        font-size: 18px;
        // margin-bottom: 12px;
      }

      input {
        border: none;
        border-bottom: 1px solid #0E283F;
        width: 100%;
        padding: 6px;
        padding-top: 0;
        margin-top: 6px;
        font-size: 18px;
      }
    }

    .upload {
      padding: 36px 0px;
      text-align: center;

      .sub-title {
        font-size: 18px;
      }

      .container {
        padding: 0;
      }

      .col {
        padding: 0;
      }

      .btn-blue {
        font-size: 18px !important;
        width: fit-content;
        margin: 0 auto;
        margin-top: 24px;
        background-color: #508BF4 !important;
        border-radius: 5px !important;
      }

      .file-remove {
        margin-left: 12px;
        font-weight: bold;
        color: red;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}