.commission-page {
    // margin-top:2rem;

    .link-btn {
        font-weight: 500;
        color: #3377EB;
        text-decoration: underline;
        margin-top: 24px;

        &:hover {
          cursor: pointer;
        }
      }

    .no-employee {
        font-size: 22px;
        width: 100%;
        text-align: center;
        padding: 24px;

        a {
            text-decoration: underline !important;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .header {
        // margin-top: 2rem;
        margin-bottom: 2rem;

        .layout-toggle {
            color: $page-color;

            span {
                margin-right: 12px;
                transition: all 0.3s ease-in-out;

                &:hover {
                    cursor: pointer;
                }
            }

            .active-icon {
                color: $page-color-primary;
            }
        }
    }

    .table-view {

    }

    .card-view {
        
    }

    .MuiDialog-container {
        .MuiDialog-paper {
            max-width: 720px;
            width: 100%;
            text-align: center;
            padding: 20px 25px;
            border-radius: 20px;

            .MuiDialogContent-root {

            }
        }
    }

    .dialog-title {
        font-size: 30px;
        font-weight: bold;
    }

    .dialog-title-2 {
        font-size: 22px;
        font-weight: bold;
    }
}

@media screen and (min-width: 1250px) {
    .commission-page {
        .card-view-mobile {
            display: none;
        }
    }
}

@media screen and (max-width: 1249px) {
    .commission-page {
        .table-view {
            display: none;
        }

        .card-view-mobile {
            display: block;
        }
    }
}

.confirm-modal {
    .top {
        padding: 24px 48px;

        .modal-title {
            font-size: 24px;
            font-weight: bold;
            color: $text-color-forth;
        }

        .modal-sub-title {
            font-size: 18px;
            font-weight: 600;
            color: #9BABC4;
        }
    }
    .bottom {
        padding: 24px 48px;

        .form-action {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .cancel-btn {
                border: 1px solid $page-color-primary;
                color: #508BF4;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 500;
                padding: 8px 18px;
                transition: all 0.3s ease-in-out;
                margin-right: 12px;

                span {
                    margin-left: 6px;
                }

                &:hover {
                    cursor: pointer;
                    transform: translateY(-2px);
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
                }
            }

            .save-btn {
                background-color: $page-color-primary;
                color: #fff;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 500;
                padding: 8px 18px;
                transition: all 0.3s ease-in-out;

                span {
                    margin-left: 6px;
                }

                &:hover {
                    cursor: pointer;
                    transform: translateY(-2px);
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
                }
            }
        }
    }
}

.formula-modal {
    .top {
        padding: 24px 48px;

        .modal-title {
            font-size: 24px;
            font-weight: bold;
            color: $text-color-forth;
            margin-bottom: 1rem;
        }

        .modal-sub-title {
            font-size: 18px;
            font-weight: 600;
            color: #9BABC4;
            margin-bottom: 1rem;
        }

        .com-option {
            border: 0.5px solid #22395B;
            border-radius: 5px;
            font-size: 18px;
            padding: 6px 0px;
            margin: 1.5rem 0px;

            &:hover {
                cursor: pointer;
                color: #fff;
                background-color: #508BF4;
                border-color: #508BF4;    
            }
        }

        .com-option-active {
            color: #fff;
            background-color: #508BF4;
            border-color: #508BF4;
        }
    }

    .divide-line {
        border-bottom: 1px solid rgba($color: #22395B, $alpha: 0.13);
    }

    .bottom {
        padding: 24px 48px;

        .form-container {
            display: flex;
            justify-content: space-between;

            .form-input {
                margin-bottom: 2rem;
                margin-top: 1rem;
                font-size: 18px;
                text-align: left;
                position: relative;
    
                .form-label {
                    font-weight: 500;
                    color: #0E283F;
                }
    
                input#percentForEmployee {
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid #0E283F;
                    line-height: 24px;
                    padding: 6px 0px;
                    background: transparent;
                    color: #0E283F;
                    height: 36px;
                    background-image: url(assets/images/percent-icon.png) !important;
                    background-position: -5px 1px !important;
                    padding-left: 25px !important;
    
                    &:focus {
                        outline: none;
                    }
                }

                input {
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid #0E283F;
                    line-height: 24px;
                    padding: 6px 0px;
                    background: transparent;
                    color: #0E283F;
                    height: 36px;
                    background-position: -5px 1px !important;
    
                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .form-action {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .cancel-btn {
                border: 1px solid $page-color-primary;
                color: #508BF4;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 500;
                padding: 8px 18px;
                transition: all 0.3s ease-in-out;
                margin-right: 12px;

                span {
                    margin-left: 6px;
                }

                &:hover {
                    cursor: pointer;
                    transform: translateY(-2px);
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
                }
            }

            .save-btn {
                background-color: $page-color-primary;
                color: #fff;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 500;
                padding: 8px 18px;
                transition: all 0.3s ease-in-out;

                span {
                    margin-left: 6px;
                }

                &:hover {
                    cursor: pointer;
                    transform: translateY(-2px);
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
                }
            }
        }
    }
}

.commission-form {
    width: 580px;
    padding: 48px;

    .form-content {
        text-align: center;
        padding-top: 2rem;

        .form-title {
            font-size: 20px;
            font-weight: bold;
            color: $text-color-forth;
        }

        .com-title {
            font-size: 18px;
            font-weight: 500;
            color: #9BABC4;
            margin-top: 0.5rem;
        }

        .form-container {
            padding: 0 24px;
            margin-top: 4rem;
            margin-bottom: 2rem;
        }
        
        .form-input {
            margin-bottom: 2rem;
            margin-top: 1rem;
            font-size: 18px;
            text-align: left;
            position: relative;

            .form-label {
                font-weight: 500;
                color: #0E283F;
            }

            input {
                width: 100%;
                border: none;
                border-bottom: 1px solid #0E283F;
                line-height: 24px;
                padding: 6px 0px;
                background: transparent;
                color: #0E283F;
                height: 36px;

                &:focus {
                    outline: none;
                }
            }

            select {
                width: 100%;
                border: none;
                border-bottom: 1px solid #0E283F;
                line-height: 24px;
                padding: 6px 0px; 
                background: transparent;
                color: #0E283F;
                height: 36px;

                &:focus {
                    outline: none;
                }
            }

            .value-field {
                width: 100%;
                border: none;
                border-bottom: 1px solid #0E283F;
                line-height: 24px;
                padding: 6px 0px;
                background: transparent;
                color: #9BABC4;
                height: 36px;
            }

            svg {
                position: absolute;
                right: -3px;
                top: 33px;
            }
        }

        .employee-form {
            border-radius: 10px;
            background-color: rgba($color: #F0F6FF, $alpha: 0.62);
            padding: 24px;
            margin-top: 24px;

            .cancel-form {
                text-align: right;
                svg {
                    transform: rotate(45deg);
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .employee-addon {
            margin-top: 2rem;
            font-size: 18px;
            color: #22395B;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            transition: all 0.3s ease-in-out;

            svg {
                transform: rotate(0deg);
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                cursor: pointer;

                svg {
                    transform: rotate(360deg);
                }
            }
        }

        .form-action {
            padding: 2rem 6rem;
        }
    }
}

@media screen and (max-width: 630px) {
    .commission-form {
        width: 450px;
        padding: 12px;

        .form-content {
            .form-action {
                padding: 2rem 2rem;
            }
        }
    }
}

@media screen and (max-width: 499px) {
    .commission-form {
        width: 325px;

        .form-content {
            .form-action {
                padding: 2rem 0rem;
            }
        }
    }
}