$breakpoint-mobile: 500px;
$breakpoint-tablet: 768px;
$breakpoint-md-device: 1024px;
$breakpoint-lg-device: 1440px;
$breakpoint-first: 1535px;
$breakpoint-second: 1300px;

$page-color: #0E283F;
$page-color-primary: #508BF4;

$bg-color-blue: #EFF5FF;
$bg-color-white: #FFF;
$bg-color-nav: #E2EDFC;
$bg-color-blue__primary: #508BF4;

$bx-shadow-color: #E1E7F2;
$bx-shadow-color1: #83b1ff;
$bx-shadow-color2: #CDD8EC;

$bd-color: #508BF4;
$bd-color-primary: #297AF5;
$bd-color-secondary: #96A7C4;

$text-color-primary: #23324E;
$text-color-secondary: #297AF5;
$text-color-third: #96A7C4;
$text-color-forth: #22395B;
$text-color-fifth: #74777B;
$text-color-sixth: #7B8FB2;

$font-minimum: 15px;
$font-minimum-mobile: 13px;
$font-primary: 18px;
$font-primary-mobile: 16px;
$font-third: 22px;
$font-third-mobile: 18px;
$font-forth: 24px;
$font-forth-mobile: 20px;
$font-secondary: 27px;
$font-secondary-mobile: 23px;
$font-medium: 30px;
$font-medium-mobile: 26px;
$font-big: 40px;
$font-big-mobile: 32px;

.font-medium {
    font-weight: 500;
}

.font-bold {
    font-weight: 700;
}

.primary-text {
    color: $text-color-primary;
}

.mt-2p {
    margin-top: 2px;
}

.position-relative {
    position: relative;
}

.display-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: column;
}

.justify-start {
    justify-content: flex-start;
}

.justify-around {
    justify-content: space-around;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.align-center {
    align-items: center;
}

.text-btn-hover {
    transition: all 0.1s ease-in-out;
    &:hover {
        text-shadow: 0px 0px 1px $text-color-secondary;
        cursor: pointer;
    }
}

.grey-text-hover {
    transition: all 0.2s ease-in-out;
    &:hover {
        text-shadow: 0px 0px 1px $text-color-fifth;
        transform: scale(1.1);
    }
}

.custom-btn-hover {

    &:hover {
        cursor: pointer;
        background-color: #ECF2FB;
        font-size: $font-primary;
        color: $text-color-sixth;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #ddd;
        margin-left: 15px;

        span {
            margin-right: 20px;
        }
    }
}

.w-100 {
    width: 100%;
}

input[type='number'] {
    background-image: url(assets/images/dollar-icon-png-3530.png) !important;
    background-repeat: no-repeat !important;
    background-size: 30px 30px !important;
    background-position: -5px -1px !important;
    padding-left: 20px !important;
}

.MuiAlert-icon {
    padding-top: 14px !important;
}

.rdtOpen {
    input {
        &:focus {
            border: 1px solid #0E283F;
        }
    }
}

.rdtPicker {
    .rdtDays {
        th {
            color: #22395B;

            span {
                color: #22395B;
            }

            &.rdtSwitch {
                color: #22395B;
            }
        }

        td {
            color: #22395B;

            span {
                color: #22395B;
            }

            .rdtSwitch {
                
            }

            &.rdtActive {
                background-color: #22395B !important;
                color: #fff !important;
            }
        }
    }
}

.layout-wrapper {
    max-width: 1920px;
    margin: 0 auto;
    font-family: 'Montserrat', ;
    font-weight: 500;

    a {
        text-decoration: none !important;
        color: $text-color-secondary !important;
    }

    .sidebar {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 10px 0 20px -2px $bx-shadow-color;
        background-color: $bg-color-white;
        transition: all ease-in 0.2s;

        .sidebar-content {
            position: relative;
            min-height: 100vh;
            padding-top: 18px;

            .drawer-icon {
                position: absolute;
                top: 20px;
                right: -18px;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background-color: $bg-color-white;
                box-shadow: 1px 1px 1px $bx-shadow-color1;
                transition: box-shadow 0.2s ease-in-out;

                &:hover {
                    cursor: pointer;
                    box-shadow: 1px 1px 6px $bx-shadow-color1;
                }

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        transform: rotate(360deg);
                    }
                }
            }

            .sidebar-header {
                overflow: hidden;
                padding: 0px 16px 16px 20px;

                .logo {
                    min-width: 49px;
                    width: 49px;
                    height: 46px;
                }
    
                .logo-text {
                    font-size: $font-secondary;
                    margin-left: 22px;
                }
            }

            .nav-list {
                overflow: hidden;

                .nav-item__active {
                    border-left: 4px solid $bd-color-primary !important;
                    background-color: $bg-color-nav !important;

                    .nav-icon__text {
                        color: $text-color-secondary !important;
                    }
                }

                .sub-menu {
                    transition: all 0.3s ease-in-out;
                    height: 320px;

                    .nav-item {
                        padding-left: 60px;

                        .nav-icon {
                            min-width: 25px;
                            width: 25px;
                            min-height: 25px;
                            height: 25px;
                        }
    
                        .nav-icon__text {
                            margin-left: 30px;
                            font-size: $font-minimum;
                        }
                    }
                }

                .sub-menu-hide {
                    overflow: hidden;
                    height: 0 !important;
                }

                .nav-item {
                    width: 310px;
                    border-left: 4px solid #fff;
                    padding: 20px 30px 20px 26px;
                    background-color: #fff;
                    transition: all 0.35s ease-in;

                    .nav-icon {
                        min-width: 30px;
                        width: 30px;
                        min-height: 30px;
                        height: 30px;
                    }

                    .nav-icon__text {
                        margin-left: 30px;
                        font-size: $font-primary;
                        color: $text-color-forth;
                    }

                    .nav-tip {
                        display: none;
                    }

                    &:hover {
                        background-color: $bg-color-nav;
                        border-left: 4px solid $bg-color-nav;
                        cursor: pointer;
                        transition: all 1s ease-in-out;

                        .nav-tip {
                            position: absolute;
                            display: flex;
                            z-index: 1;
                            right: -190px;
                            width: 170px;
                            height: 54px;
                            background-color: #fff;
                            box-shadow: 1px 1px 10px #B2C3E4;
                            border-radius: 5px;
    
                            span {
                                font-size: $font-primary;
                                font-weight: 600;
                                color: $text-color-sixth;
                            }
                        }
    
                        .nav-tip-arrow {
                            position: absolute;
                            z-index: 2;
                            right: -28px;
                            width: 15px;
                            height: 15px;
                            background-color: #fff;
                            transform: rotate(45deg);
                        }

                        .advanced-nav-tip-arrow-2 {
                            display: block;
                        }
                    }

                    .advanced-nav-tip {
                        display: block;
                        position: absolute;
                        left: 110px;
                        bottom: 245px;
                        z-index: 1;
                        background-color: #fff;
                        box-shadow: 1px 1px 10px #B2C3E4;
                        border: 1px solid #fff;
                    }

                    .advanced-nav-tip-arrow {
                        position: absolute;
                        z-index: 2;
                        right: -28px;
                        width: 15px;
                        height: 15px;

                        svg {
                            font-size: 35px;
                            color: #fff;
                            margin-left: -13px;
                            margin-top: -10px;
                        }
                    }

                    .advanced-nav-tip-arrow-2 {
                        display: none;
                        position: absolute;
                        z-index: 2;
                        right: -28px;
                        width: 15px;
                        height: 15px;

                        svg {
                            font-size: 35px;
                            color: #fff;
                            margin-left: -13px;
                            margin-top: -10px;
                        }
                    }

                    .report-addon {
                        color: $text-color-forth;
                        margin-left: 60px;
                        transition: all 0.3s ease-in-out;
                        transform: rotate(0deg);
                    }

                    .addon-down {
                        transform: rotate(450deg);
                    }
                }
            }
        }
    }

    .overlay {
        // background-color: rgba($color: #000000, $alpha: 0.5);
    }

    .mobile-view {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;

        .mobile-sidebar {
            box-shadow: 2px 2px 10px $bx-shadow-color2;
            background-color: $bg-color-white;
            transition: all ease-in 0.2s;
            overflow: hidden;
    
            .sidebar-content {
                position: relative;
                min-height: 100vh;
                padding-top: 18px;
    
                .drawer-icon {
                    position: absolute;
                    top: 20px;
                    right: 18px;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    background-color: $bg-color-white;
                    box-shadow: 1px 1px 6px $bx-shadow-color1;
                    transition: box-shadow 0.2s ease-in-out;
    
                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.3s ease-in-out;
    
                        &:hover {
                            transform: rotate(360deg);
                        }
                    }
                }
    
                .sidebar-header {
                    overflow: hidden;
                    padding: 0px 16px 16px 16px;
    
                    .logo {
                        width: 49px;
                        height: 46px;
                    }
        
                    .logo-text {
                        font-size: $font-secondary;
                        margin-left: 10px;
                    }
                }
    
                .nav-list {
                    overflow: hidden;
    
                    .nav-item__active {
                        border-left: 4px solid $bd-color-primary !important;
                        background-color: $bg-color-nav !important;
    
                        .nav-icon__text {
                            color: $text-color-secondary !important;
                        }
                    }
    
                    .nav-item {
                        width: 310px;
                        border-left: 4px solid #fff;
                        padding: 20px 30px;
                        background-color: #fff;
                        transition: all 0.35s ease-in;
    
                        .nav-icon {
                            width: 30px;
                            height: 30px;
                        }
    
                        .nav-icon__text {
                            margin-left: 26px;
                            font-size: $font-primary;
                            color: $text-color-forth;
                        }
    
                        &:hover {
                            background-color: $bg-color-nav;
                            border-left: 4px solid $bg-color-nav;
                            cursor: pointer;
    
                            .nav-tip {
                                position: absolute;
                                z-index: 1;
                                right: -180px;
                                width: 160px;
                                height: 54px;
                                background-color: #fff;
                                box-shadow: 1px 1px 10px #B2C3E4;
                                border-radius: 5px;
        
                                span {
                                    font-size: $font-primary;
                                    font-weight: 600;
                                    color: $text-color-sixth;
                                }
                            }
        
                            .nav-tip-arrow {
                                position: absolute;
                                z-index: 2;
                                right: -28px;
                                width: 15px;
                                height: 15px;
                                background-color: #fff;
                                transform: rotate(45deg);
                            }
                        }
                    }

                    .sub-menu {
                        .nav-item {
                            padding-left: 80px;
                        }

                        height: 320px;
                        transition: all 0.3s ease-in-out;
                    }

                    .sub-menu-hide {
                        overflow: hidden;
                        height: 0 !important;
                    }

                    .report-addon {
                        color: $text-color-forth;
                        margin-left: 60px;
                        transition: all 0.3s ease-in-out;
                        transform: rotate(0deg);
                    }

                    .addon-down {
                        transform: rotate(450deg);
                    }
                }
            }
        }
    }

    .content {
        width: 100%;
        min-height: 1080px;
        // background-color: $bg-color-blue;
        transition: all 0.2s ease-in-out;
        padding-bottom: 50px;

        .content-header {
            padding: 14px 0px;
            border-bottom: 1px solid $bd-color-secondary;

            .search {
                color: $text-color-third;
                font-size: $font-primary !important;
                width: 33%;

                .search-placeholder {
                    padding: 4px 8px;
                    border: none;
                    background-color: $bg-color-blue;
                    font-size: $font-primary !important;

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        color: $text-color-third;
                        font-size: $font-primary !important;
                    }
                }
            }

            .page-title {
                width: 33%;

                .page-icon {
                    width: 24px;
                    height: 20px;
                    margin-right: 18px;

                    img {
                        max-width: 100%;
                    }
                }

                .title-text {
                    font-size: $font-third;
                }
            }

            .user-profile {
                color: $text-color-third;
                font-size: 20px;
                width: 33%;

                .profile-avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 12px;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid #96A7C4;
                    overflow: hidden;
                    background-color: #fff;
                    box-shadow: 0px 3px 6px rgba($color: #96A7C4, $alpha: 0.5);

                    img {
                        max-width: 100%;
                    }
                }

                .logout-icon {
                    margin-left: 12px;
                    transform: rotate(0deg);
                    transition: all 0.2s ease-in-out;
                }

                &:hover {
                    cursor: pointer;

                    .logout-icon {
                        transform: rotate(360deg);
                    }
                }
            }

            .drawer-icon {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background-color: $bg-color-white;
                box-shadow: 1px 1px 6px $bx-shadow-color1;
                margin-right: 10px;
            }
        }

        .pages {
            padding-top: 48px;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .layout-wrapper {
        .content {
            .content-header {
                .page-title {
                    width: 70%;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    
}