.new-employee-dialog {
    position: relative;

    .cancel-dialog-icon {
        position: absolute;
        top: -10px;
        right: -10px;
    
        svg {
            transform: rotate(45deg);
    
            &:hover {
                cursor: pointer;
            }
        }
    }    
}

.btn-blue {
    background-color: $page-color-primary !important;
    color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px !important;
    padding: 12px 18px;
    transition: all 0.3s ease-in-out;

    span {
        margin-left: 6px;
    }

    &:hover {
        cursor: pointer;
        transform: translateY(-2px);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);

        svg {
            transform: rotate(360deg);
        }
    }

    svg {
        transition: all 0.3s ease-in-out;
        transform: rotate(0deg);
    }
}

.btn-red {
    background-color: #cf0423 !important;
    color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px !important;
    padding: 12px 18px;
    transition: all 0.3s ease-in-out;

    span {
        margin-left: 6px;
    }

    &:hover {
        cursor: pointer;
        transform: translateY(-2px);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);

        svg {
            transform: rotate(360deg);
        }
    }

    svg {
        transition: all 0.3s ease-in-out;
        transform: rotate(0deg);
    }
}

.btn-blue-add-employee {
    background-color: $page-color-primary !important;
    color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px !important;
    padding: 12px 18px;
    transition: all 0.3s ease-in-out;

    span {
        margin-left: 6px;
    }

    &:hover {
        cursor: pointer;
        transform: translateY(-2px);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);

        svg {
            transform: rotate(360deg);
        }
    }

    svg {
        transition: all 0.3s ease-in-out;
        transform: rotate(0deg);
    }
}

.employee-page {
    // margin-top: 2rem;

    .header {
        // margin-top: 2rem;
        margin-bottom: 2rem;

        .layout-toggle {
            color: $page-color;

            span {
                margin-right: 12px;
                transition: all 0.3s ease-in-out;

                &:hover {
                    cursor: pointer;
                }
            }

            .active-icon {
                color: $page-color-primary;
            }
        }
    }

    .table-view {

    }

    .card-view {
        
    }

    .MuiDialog-container {
        .MuiDialog-paper {
            max-width: 720px;
            width: 100%;
            text-align: center;
            padding: 20px 25px;
            border-radius: 20px;

            .MuiDialogContent-root {

            }
        }
    }

    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            font-size: 30px;
            font-weight: bold;
        }
    }

    .dialog-title-2 {
        font-size: 22px;
        font-weight: bold;
    }
}

@media screen and (min-width: 1250px) {
    .employee-page {
        .card-view-mobile {
            display: none;
        }
    }
}

@media screen and (max-width: 1249px) {
    .employee-page {
        .table-view {
            display: none;
        }

        .card-view-mobile {
            display: block;
        }
    }
}