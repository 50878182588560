.paynow-page {
  .payments-table {
    background-color: white;
    border-radius: 10px;
    border-collapse: collapse;
    // table-layout: fixed;
    margin-top: 48px;
    thead {
      font-weight: 700;
    }

    .sub-thead {
      font-weight: 700;
      border: none;
      td {
        padding-bottom: 0;
      }
    }

    .sub-tbody {
      border: none;
      td {
        padding-bottom: 0;
      }
    }
    
    tr {
      border-bottom: 0.5px solid rgba(14, 40, 63, 0.38);
      td {
        padding: 17px 0;
        &:first-child {
          padding-left: 27px;
        }
        &:last-child {
          padding-Right: 27px;
        }
        input {
          max-width: 80%;
        }
        #amountPaid {
          border: 1px solid black;
        }
      }

    }

    tbody {
      tr {
        &:last-child {
          border-bottom: none;
        }
        .toggle-btn {
          background-color: white;
          border: none;
          padding: 0;
          &:focus {
            outline: none;
          }
        }
      }
      .grey-btn {
        background-color: #5A7288;
        border-radius: 5px;
        text-align: center;
        padding: 2px 16px;
        width: fit-content;
        color: #fff;
        transition: all 0.3s ease-in-out;
        border: none;

        &:hover {
          cursor: pointer;
          box-shadow: 0px 3px 6px #5A7288;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
  // padding: 48px 0px;
  color: #0E283F;
  font-weight: 500;

  .page-title {
    width: 100%;
    font-size: 20px;
    // padding: 24px;
    text-align: center;
  }

  .pay-content {
    overflow-x: auto;
    .no-pay {
      width: 100%;
      font-size: 20px;
      text-align: center;
      padding: 24px;
      background-color: #fff;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.39);
      border-radius: 10px;
      margin-top: 48px;
    }

    .pay-element {
      padding: 24px;
      background-color: #fff;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.39);
      border-radius: 10px;
      margin: 48px 0px;

      .header {
        position: relative;
        border-bottom: 0.5px solid #96A7C4;
        padding-bottom: 24px;

        .title {
          padding-top: 0 !important;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
        }

        .remove-icon {
          position: absolute;
          top: 2px;
          right: 0;
          width: 31px;
          height: 31px;
          background-color: #5A7288;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;

          svg {
            transform: rotate(45deg);
            color: #fff;
            transition: all 0.3s ease-in-out;
          }

          &:hover {
            cursor: pointer;
            box-shadow: 0px 3px 6px #96A7C4;

            svg {
              transform: rotate(315deg);
              color: #fff;
            }
          }
        }
      }

      .element-content {
        display: flex;
        justify-content: space-between;
        // border-bottom: 0.5px solid #96A7C4;
        padding-bottom: 24px;
        background-color: rgba($color: #F0F6FF, $alpha: 0.6);
        margin: 24px 0px;

        .left {
          width: 60%;
          display: flex;
          flex-wrap: wrap;
          padding-right: 24px;
          margin-top: 24px;
          padding-top: 24px;
          border-right: 0.5px solid #96A7C4;
          padding-bottom: 24px;

          .date {
            width: 20%;
            font-size: 18px;
            padding: 0 8px;
            margin-bottom: 24px;

            .text {
              font-weight: bold;
            }

            .value {
              margin-top: 4px;
            }
          }

          .sub-title {
            width: 30%;
            font-size: 18px;
            padding: 0 8px;
            margin-bottom: 24px;
            
            .text {
              font-weight: bold;
            }

            .value {
              margin-top: 4px;
            }
          }

          .customer {
            width: 18%;
            font-size: 18px;
            padding: 0 8px;
            margin-bottom: 24px;
            
            .text {
              font-weight: bold;
            }

            .value {
              margin-top: 4px;
            }
          }

          .gross {
            width: 16%;
            font-size: 18px;
            padding: 0 8px;
            margin-bottom: 24px;
            
            .text {
              font-weight: bold;
            }

            .value {
              margin-top: 4px;
            }
          }

          .owed {
            width: 16%;
            font-size: 18px;
            padding: 0 8px;
            margin-bottom: 24px;

            .text {
              font-weight: bold;
            }

            .value {
              margin-top: 4px;
            }
          }

          .pay {
            width: 32%;
            font-size: 18px;
            padding: 0 8px;
            // margin-bottom: 24px;
            
            .text {
              font-weight: bold;
            }

            .value {
              margin-top: 4px;
              border-bottom: 1px solid #000;

              input {
                border: none;
                overflow: hidden;
                max-width: 100%;
                background: transparent;

                &:focus {
                  outline: 0;
                }
              }
            }
          }

          .reason {
            width: 32%;
            font-size: 18px;
            padding: 0 8px;
            // margin-bottom: 24px;
            
            .text {
              font-weight: bold;
            }

            .value {
              margin-top: 4px;
              border-bottom: 1px solid #000;

              input {
                border: none;
                overflow: hidden;
                max-width: 100%;
                background: transparent;

                &:focus {
                  outline: 0;
                }
              }
            }
          }

          .pay-btn {
            width: 36%;
            font-size: 18px;
            padding: 0 8px;
            display: flex;
            align-items: flex-end;
            
            .grey-btn {
              background-color: #5A7288;
              border-radius: 5px;
              text-align: center;
              padding: 2px 16px;
              margin-bottom: 8px;
              width: fit-content;
              color: #fff;
              transition: all 0.3s ease-in-out;

              &:hover {
                cursor: pointer;
                box-shadow: 0px 3px 6px #5A7288;
              }

              &:last-child {
                margin-left: 12px;
              }
            }
          }
        }

        .right {
          width: 40%;
          height: 100%;
          font-size: 18px;
          margin-top: 24px;
          padding-left: 24px;
          padding-top: 24px;

          .headers {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .field {
              // width: 25%;
              font-weight: bold;

              &:first-child {
                width: 15%;
              }

              &:nth-child(2) {
                width: 18%;
              }

              &:nth-child(3) {
                width: 25%;
              }

              &:last-child {
                width: 22%;
              }
            }
          }

          .records {
            overflow: auto;
            max-height: 160px;

            &::-webkit-scrollbar {
              width: 5px;
              // height: 5px;
              border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
              background: rgba($color: #508BF4, $alpha: 0.2); 
            }

            &::-webkit-scrollbar-thumb {
              background:#508BF4;
              border-radius: 10px;
            }

            .record {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 12px 0px;
              // min-width: 540px;
  
              .field-record {
                &:first-child {
                  width: 15%;
                }

                &:nth-child(2) {
                  width: 18%;
                }

                &:nth-child(3) {
                  width: 25%;
                }

                &:last-child {
                  width: 22%;
                }
              }
            }

            .record-mobile {
              display: none;
              .field-record {
                display: flex;
                justify-content: space-between;
                align-items: center;

                div:first-child {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1249px) {
  .paynow-page {
    .pay-content {
      .pay-element {
        .element-content {
          display: block;

          .left {
            width: 100%;
            border-right: 0;
            border-bottom: 0.5px solid #96A7C4;
            padding-right: 0;
          }

          .right {
            width: 100%;
            padding: 0px 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .paynow-page {
    .pay-content {
      .pay-element {
        .element-content {
          .left {
            .date {
              width: 50%;
            }

            .sub-title {
              width: 50%;
            }

            .customer {
              width: 50%;
            }

            .gross {
              width: 50%;
            }

            .owed {
              width: 50%;
            }

            .pay {
              width: 50%;
              overflow: hidden;
            }

            .reason {
              width: 50%;
              overflow: hidden;
            }

            .pay-btn {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 626px) {
  .paynow-page {
    .pay-content {
      .pay-element {
        .element-content {

          .right {
            .headers {
              display: none;
            }

            .records {
              .record {
                display: none;
              }

              .record-mobile {
                display: block;
                border-bottom: 0.5px solid #96A7C4;
                padding-bottom: 8px;
                padding-top: 8px;
                padding: 8px 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 499px) {
  .paynow-page {
    .pay-content {
      .pay-element {
        .element-content {
          .left {
            .date {
              width: 100%;
            }

            .sub-title {
              width: 100%;
            }

            .customer {
              width: 100%;
            }

            .gross {
              width: 100%;
            }

            .owed {
              width: 100%;
            }

            .pay {
              width: 100%;
              overflow: hidden;
              margin-bottom: 2rem;
            }

            .reason {
              width: 100%;
              overflow: hidden;
              margin-bottom: 2rem;
            }

            .pay-btn {
              width: 100%;
            }
          }

          .right {
            .headers {
              display: none;
            }

            .records {
              .record {
                display: none;
              }

              .record-mobile {
                display: block;
                border-bottom: 0.5px solid #96A7C4;
                padding-bottom: 8px;
                padding-top: 8px;
                padding: 8px 8px;
              }
            }
          }
        }
      }
    }
  }
}

