.period-dropdown {
    background-color: #fff;
    position: absolute;
    top: 30px;
    left: -2px;
    padding: 8px 0px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0,0,0,.16);
    width: 80px;
    z-index: 100;

    .img-container {
        width: 15px;
        margin-right: 10px;
    }
}

.period-dropdown > div {
    padding-left: 8px;
    padding-right: 8px;

    &:hover {
        cursor: pointer;
        background-color: $bg-color-blue;
    }
}

.thousands {
    top: 40px;
    width: 220px;
}

.custom-tooltip-hidden {
    // opacity: 0 !important;
    display: none !important;
}

.custom-tooltip {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba($color: #8cb2f3, $alpha: 0.5);
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    border-radius: 4px;
    // opacity: 1;

    span:first-child {
        color: #7B8FB2;
    }

    span:last-child {
        color: #508BF4;
    }

    .tooltip-label {
        color: #7B8FB2;
        margin: 0 8px;
    }

    .tooltip-value {
        color: #508BF4;
    }

    .blue-dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #508BF4;
        border-radius: 50%  ;
    }
}

.cancel-btn {
    border: 1px solid $page-color-primary;
    color: #508BF4;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    padding: 8px 18px;
    transition: all 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        transform: translateY(-2px);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
    }
}

.home-wrapper {
    // padding-top: 20px;

    @keyframes width-pulse {
        0% {
            stroke-width: 4px
        }
        50% {
            stroke-width: 8px;
        }
        100% {
            stroke-width: 4px;
        }
    }
    @keyframes dashoffset-seven {
        0% {
            stroke-dashoffset: 7px;
        }
        100% {
            stroke-dashoffset: 0px;
        }
    }
    @keyframes dasharray-craziness {
        0% {
            stroke-dasharray: 7px 2px;
        }
        80% {
            stroke-dasharray: 7px 100px;
            stroke-width: 10px
        }
        100% {
            stroke-dasharray: 7px 2px;
        }
    }

    .gross-graph {
        padding-top: 15px;

        .ct-line {
            stroke-width: 2px;
            // stroke-dasharray: 5px 2px;
            // animation: dashoffset-seven 200ms infinite linear;
        }

        .ct-series-b {
            .ct-line {
                stroke: #FF00CD !important;
            }

            .ct-point {
                stroke: #FF00CD !important;
            }
        }
    }

    .payroll-graph {
        padding-top: 15px;
        .ct-bar {
            stroke-width: 6px;
            stroke-linecap: round;
            animation: width-pulse 2s infinite;
        }
    }

    .expenses-graph {
        padding-top: 15px;
        .ct-line {
            stroke-width: 2px;
            // stroke-linecap: round;
            // stroke-dasharray: 5px 5px;
            // // animation: dasharray-craziness 10s infinite linear;
            // animation: dashoffset-seven 200ms infinite linear;
        }
    }

    .ct-chart {
        border-radius: 3px;
        position: relative;
    }
    
    .ct-chart-gross {
        //   background: linear-gradient(60deg, #66bb6a, #43a047);
        background: transparent;
    }
    
    .ct-chart-payroll {
        //   background: linear-gradient(60deg, #ffa726, #fb8c00);
        background: transparent;
    }
    
    .ct-chart-expenses {
        //   background: linear-gradient(60deg, #ef5350, #e53935);
        background: transparent;
    }
    
    .ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut {
        stroke: #508BF4 !important;
    }
    
    .ct-grid {
    //   stroke: hsla(0, 0%, 100%,.2);
        stroke: #C8D0DE;
        stroke-width: 1px;
        stroke-dasharray: 2px;
    }

    .ct-grids {
        margin: 0 5px !important;
    }

    .ct-vertical {
        width: 40px !important;
        stroke: #C8D0DE !important;
    }

    .ct-horizontal {

    }
    
    .ct-label {
        line-height: 1;
        color: #C8D0DE !important;
        fill: #C8D0DE !important;
        font-size: 15px;
    }
    
    .statics {
        min-width: 23%;
        // margin: 18px 0px;
        margin-bottom: 36px;

        .custom-card {
            transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;

            &:hover {
                box-shadow: 0 1.5rem 2.5rem rgba(22,28,45,.1),0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important;
                transform: translate3d(0,-5px,0);
            }
        }
        
        .img-icon {
            margin-right: 18px;
        }
    
        .card-values {
            .value-big {
                font-size: $font-big;
                color: $text-color-forth;
                margin-top: -10px;
            }
    
            .value-small {
                font-size: $font-forth;
                color: $text-color-sixth;
                margin-top: -8px;
            }
        }
    
        .period {
            color: $text-color-sixth;
            font-size: $font-primary;
            position: relative;
    
            img {
                margin-right: 4px;
            }

            span {
                margin-left: 4px;
                margin-right: 4px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    
        .action-btn {
            color: $text-color-secondary;
            text-decoration: none;
            font-size: $font-primary;

            span:last-child {
                margin-top: 2px;
            }
        }
    }

    .overview-title {
        font-size: $font-medium;
    }

    .overview {
        .header {
            .card-logo-outer {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: rgba(80, 139, 244, 0.18);

                .card-logo-inner {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    background-color: $bg-color-blue__primary;
                }
            }

            .card-title {
                font-size: $font-forth;
                color: $text-color-forth;
                margin-left: 10px;
                margin-bottom: 0 !important;
            }

            .actions {
                span {
                    margin-right: 4px;
                    color: $text-color-secondary;
                    font-size: $font-primary;
                }

                a {
                    color: $text-color-secondary;
                    font-size: $font-primary;
                }
            }
        }

        .sub-header {
            margin-top: 5px;
            margin-left: 60px;

            .graph-label {
                color: $text-color-sixth;
                font-size: $font-minimum;

                .pink-line {
                    display: inline-block;
                    width: 19px;
                    border-bottom: 2px solid #FF00CD;
                    margin: 0px 8px 0px 8px;
                }

                .blue-line {
                    display: inline-block;
                    width: 19px;
                    border-bottom: 2px solid #508BF4;
                    margin-top: 4px;
                    margin: 0px 8px 0px 8px;
                }
            }

            .period-filter-btn {
                color: $text-color-sixth;
                font-size: $font-minimum;
                margin: 0px 4px;
                padding: 4px 8px;
            }

            .period-filter-btn__active {
                color: rgb(80, 139, 244);
                cursor: pointer;
                background-color: #ECF2FB;
                font-size: $font-primary;
                border-radius: 5px;
                // box-shadow: 1px 1px 5px #ddd;
                margin-left: 15px;
            }
        }

        .gross-sales {
            width: 68%;
            // height: 332px;
            margin-top: 20px;
        }

        .top-performers{
            width: 30%;
            // height: 332px;
            margin-top: 20px;

            .top-performers-content {
                padding: 0 10px;
            }

            .table-header {
                font-size: 15px;
                color: #95A0B4;
                font-weight: 600;
                width: 100%;
                padding: 5px 5px;
                border-bottom: 1px solid #C8D0DE;

                .title-1 {
                    width: 20%;
                    text-align: left;
                }

                .title-2 {
                    width: 50%;
                    text-align: left;
                }

                .title-3 {
                    width: 30%;
                    text-align: right;
                }
            }

            .table-content {

                .table-record {
                    font-size: 15px;
                    font-weight: 400;
                    width: 100%;
                    padding: 5px 5px;
                    border-bottom: 1px solid #C8D0DE;

                    .record-1 {
                        width: 20%;
                        text-align: left;
                        color: #7B8FB2;
                    }

                    .record-2 {
                        width: 50%;
                        text-align: left;
                        color: $text-color-forth;
                    }

                    .record-3 {
                        width: 30%;
                        text-align: right;
                        color: $text-color-forth;
                    }
                }

                .table-record-odd {
                    background-color: #F2F6FE;
                }
            }
        }

        .payroll-history {
            width: 49%;
            margin-top: 36px;
        }

        .gross-expenses {
            width: 49%;
            margin-top: 36px;
        }
    }
}

@media screen and (max-width: $breakpoint-first) {
    .home-wrapper {
        .statics {
            .card-values {
                .value-big {
                    font-size: $font-big-mobile;
                }

                .value-small {
                    font-size: $font-forth-mobile;
                }
            }

            .action-btn {
                font-size: $font-primary-mobile;

                span:last-child {
                    margin-top: 0px;
                }
            }
        }

        .overview-title {
            font-size: $font-medium-mobile;
        }

        .overview {
            .header {
                .card-title {
                    font-size: $font-forth-mobile;
                }

                .actions {
                    font-size: $font-primary-mobile;
                }
            }

            .sub-header {
                .period-filter-btn {
                    font-size: $font-primary-mobile;
                }

                .period-filter-btn__active {
                    font-size: $font-primary-mobile;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-second) {
    .home-wrapper {
        .overview {
            .gross-sales {
                width: 100%;
            }

            .top-performers {
                width: 100%;
            }

            .payroll-history {
                width: 100%;
            }

            .gross-expenses {
                width: 100%;
            }
        }

        .statics {
            width: 47%;
        }
    }

    .home-wrapper .overview > div {
        display: block;
    }

    .home-wrapper {
        .ct-label {
            font-size: 14px !important;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .home-wrapper {
        foreignObject:nth-child(even) {
            display: none !important;
        }
    }
}

@media screen and (min-width: $breakpoint-mobile) {
    .bottom-actions {
        display: none;
    }

    .top-period {
        display: none;
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .home-wrapper {
        .ct-label {
            font-size: 12px !important;
        }

        .ct-point {
            stroke-width: 5px !important;
        }

        .bottom-actions {
            display: flex;
            color: $text-color-secondary !important;
            margin-top: 20px;
        } 
    }
    
    .card {
        padding: 15px 10px 10px 10px;
    }

    .top-period {
        display: block;

        .tab-btn__active {
            font-size: 15px;
            padding-bottom: 8px;
            margin-bottom: 15px;
            border-bottom: 2px solid $text-color-secondary;
        }

        .tab-btn {
            font-size: 15px;
            padding-bottom: 8px;
            margin-bottom: 15px;
            border-bottom: 2px solid transparent;
        }
    }

    .pages {
        padding: 0px;

        .home-wrapper > div {
            display: block;
    
            .statics {
                width: 100%;
            }
        }

        .home-wrapper {
            
            .overview {
                .header > div:first-child {
                    width: 100%;
                    justify-content: flex-start;

                    .card-title {
                        font-size: 16px !important;
                    }
                }

                .header {
                    .actions {
                        display: none;
                    }
                }

                .sub-header > div:last-child {
                    display: none;
                }
            }
        }
    }
}