.table-container {
    width: 100%;
    height: auto;
    border-radius: 5px;
    border-radius: 5px;
    box-shadow: 3px 20px 50px #D1DEF1;
    background-color: #fff;
    color: #22395B;
    // font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    .item-1 {
        width: 7%;
        text-align: center !important;
    }

    .item-2 {
        width: 22%;
    }

    .item-3 {
        width: 14%;
    }

    .item-4 {
        width: 18%;
    }

    .item-5 {
        width: 13%;
    }

    .item-6 {
        width: 13%;
    }
    
    .item-7 {
        width: 13%;
        text-align: center !important;
    }

    .item-8 {
        width: 13%;
        text-align: center !important;
    }

    .item-9 {
        width: 37%;
    }

    .item-10 {
        width: 37%;
    }

    .item-11 {
        width: 13%;
        text-align: center !important;
    }


    .table-header {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #DCE8F6;
        font-size: 20px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 13px 0px;

        .header-item {
            padding: 5px 25px;
            text-align: left;
        }
    }

    .table-body {
        // font-family: 'Franklin Gothic Book  ', 'Arial Narrow', Arial, sans-serif;

        .table-record {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 13px 0px;
            border-bottom: 0.5px solid rgba(171,186,204, 0.22);

            .body-item {
                padding: 5px 25px;
                text-align: left;
                font-size: 18px;

                .name-badge {
                    display: inline-block;
                    width: 31px;
                    height: 31px;
                    border-radius: 50%;
                    margin-right: 10px;
                    font-size: 14px;
                    padding-top: 5px;
                    text-align: center;
                }

                .status-badge {
                    border-radius: 5px;
                    padding: 5px 10px;
                }

                .heavy-bg {
                    background-color: #5A7288;
                    color: #fff !important;
                }

                .light-bg {
                    background-color: #CBDDF2;
                }

                .action {
                    position: relative;
                    padding: 8px 10px;

                    &:hover {
                        cursor: pointer;
                        background-color: #EBEBEB;
                        padding: 8px 10px;
                        border-radius: 5px;
                    }

                    .actions {
                        z-index: 10;
                        position: absolute;
                        right: 0;
                        top: 40px;
                        background-color: #fff;
                        border-radius: 5px;
                        box-shadow: 0px 3px 6px rgba($color: #000, $alpha: 0.16);
                        font-size: 18px;
                        width: 120px;

                        .action-btn {
                            padding: 4px 12px;
                            text-align: left;

                            svg {
                                margin-right: 12px;
                            }

                            &:hover {
                                background-color: #E4E4E4;
                            }
                        }
                    }
                }
            }
        }
    }
}

.card-view-container {
    width: 100%;
    height: auto;    
    color: #22395B;
    
    // font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    .card-view-body {
        display: flex;
        // justify-content: space-between;
        justify-content: flex-start;
        flex-wrap: wrap;

        .empty-record {
            min-height: 285px;

            .card-content {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .plus-1 {
                    border: 1px solid #000;
                    width: 10px;
                    height: 10px;
                }
                .plus-2 {
                    border: 1px solid #000;
                    width: 10px;
                    height: 10px;
                }
                .plus-3 {
                    border: 1px solid #000;
                    width: 10px;
                    height: 10px;
                }
                .plus-4 {
                    border: 1px solid #000;
                    width: 10px;
                    height: 10px;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        .card-record {
            width: 23.5%;
            margin: 0 2% 40px 0px;
            border-radius: 10px;
            box-shadow: 3px 20px 50px #D1DEF1;
            transition: all 0.4s ease-in-out;

            &:nth-child(4n) {
                margin-right: 0;
            }

            &:hover {
                box-shadow: 0 1.5rem 2.5rem rgba(22,28,45,.1),0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important;
                transform: translate3d(0,-5px,0);
            }

            .card-image {
                height: 8px;
                background-color: #508BF4;
                opacity: 1;
                overflow: hidden;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;

                img {
                    width: 100%;
                    max-width: 100%;
                }
            }

            .card-image-light {
                background-color: #508BF4;
                opacity: 0.4;
            }

            .card-content {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 25px;
                background: #fff;
                font-size: 18px;

                .title {
                    font-size: 22px;
                    font-weight: bold;
                    text-align: center;
                    padding-top: 4px !important;
                    padding-bottom: 10px;
                }

                .card-column {
                    margin: 4px 0px;
                    span:first-child {
                        margin-right: 10px;
                    }
                }

                .card-action {
                    margin-top: 24px;

                    span {
                        border: 1px solid $page-color;
                        border-radius: 50%;
                        width: 32px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            transform: rotate(360deg);
                            border-color: $page-color-primary;
                            color: $page-color-primary;
                            box-shadow: 0px 1px 1px $page-color-primary;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1349px) {
    .card-view-container {    
        .card-view-body {    
            .card-record {
                width: 32%;
                margin-right: 2%;

                &:nth-child(4n) {
                    margin-right: 2%;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .card-view-container {    
        .card-view-body {    
            .card-record {
                width: 45%;
                margin-right: 10%;

                &:nth-child(3n) {
                    margin-right: 10%;
                }

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 630px) {
    .card-view-container {    
        .card-view-body {
            justify-content: center;
            .card-record {
                width: 75%;
                
                &:first-child {
                    margin: 20px auto;
                }

                &:nth-child(2n) {
                    margin: 20px auto;
                }

                &:nth-child(3n) {
                    margin: 20px auto;
                }

                &:nth-child(4n) {
                    margin: 20px auto;
                }

                &:nth-child(5n) {
                    margin: 20px auto;
                }
            }
        }
    }

    .employee-page {
        .MuiDialog-container {
            .MuiDialog-paper {
                padding: 10px 5px;
            }
        }

        .MuiBox-root {
            max-width: 475px;
        }
    }
}

@media screen and (max-width: 499px) {
    .card-view-container {    
        .card-view-body {
            justify-content: center;
            .card-record {
                width: 100%;
            }
        }
    }

    .employee-page {
        .MuiDialog-container {
            .MuiDialog-paper {
                padding: 10px 5px;
            }
        }

        .MuiBox-root {
            max-width: 350px;
        }
    }
}