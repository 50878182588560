.custom-card {
    background-color: $bg-color-white;
    border-radius: 10px;
    border: none;
    box-shadow: 1px 1px 10px $bx-shadow-color2;
    padding: 25px 20px 20px 20px;
    max-width: 100%;
    height: 100%;

    transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;

    &:hover {
        box-shadow: 0 1.5rem 2.5rem rgba(22,28,45,.1),0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important;
        transform: translate3d(0,-5px,0);
    }
}