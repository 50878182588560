.receive-funds-page {
    color: #22395B;
    
    .page-content {
        display: flex;
        justify-content: space-between
    }
    
    .card-left {
        max-width: 500px;
        width: 60%;
        background-color: #fff;
        // margin: 48px 0;
        margin-right: 12px;
        border-radius: 10px;
        box-shadow: 0px 20px 20px rgba($color: #ABBBD4, $alpha: 0.39);
        padding: 48px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .card-icon {
            text-align: center;
            margin-bottom: 48px;

            img {
                max-width: 100%;
            }
        }

        .card-title {
            font-size: 24px;
            font-weight: bold;
        }
    }

    .card-content {
        max-width: 855px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        // margin: 48px 0;
        // margin-left: 12px;
        border-radius: 10px;
        box-shadow: 0px 20px 20px rgba($color: #ABBBD4, $alpha: 0.39);
        padding: 48px 24px;
        font-size: 20px;

        .card-title {

        }

        .card-form {
            display: flex;
            gap: 20px;
            .card-individual {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background-color: #F0F6FF;
                padding: 12px 20px 
            }
        }
    }

    .form-input {
        // margin-bottom: 2rem;
        margin-top: 2rem;
        font-size: 18px;
        text-align: left;
        position: relative;

        .form-label {
            font-weight: 500;
            color: #0E283F;
        }

        input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #0E283F;
            line-height: 24px;
            padding: 6px 0px;
            padding-top: 0;
            background: transparent;
            color: #0E283F;
            height: 36px;

            &:focus {
                outline: none;
            }
        }

        select {
            width: 100%;
            border: none;
            border-bottom: 1px solid #0E283F;
            line-height: 24px;
            padding: 6px 0px; 
            background: transparent;
            color: #0E283F;
            height: 36px;

            &:focus {
                outline: none;
            }
        }

        .value-field {
            width: 100%;
            border: none;
            border-bottom: 1px solid #0E283F;
            line-height: 24px;
            padding: 6px 0px;
            background: transparent;
            color: #9BABC4;
            height: 36px;
        }

        svg {
            position: absolute;
            right: -3px;
            top: 33px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .receive-funds-page {
        .page-content {
            display: block;

            .card-left {
                max-width: 100%;
                width: 100%;
                margin-right: 0;
                align-items: center;

                .card-icon {
                    margin-bottom: 24px;
                }

                .card-title {
                }
            }

            .card-content {
                margin-left: 0;
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .receive-funds-page {
        .page-content {
            display: block;

            .card-left {
                max-width: 100%;
                width: 100%;
                margin-right: 0;
                padding: 24px;

                .card-icon {
                    margin-right: 12px;
                }

                .card-title {
                }
                
            }

            .card-content {
                margin-left: 0;
                max-width: 100%;
                padding: 24px;
                .card-form {
                    flex-direction: column;
                    .card-individual {
                        width: 100%;
                    }
                }
            }
        }
    }
}