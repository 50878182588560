.demo-content {
    width: 70%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 20px 20px rgba(192, 195, 199, 0.41);
    margin: auto;
    padding: 100px;

    .demo-title {
        color: #0149c5;
        font-size: 2rem;
        font-style: normal;
        line-height: 1.2;
        width: 100%;
        text-align: center;
    }

    .demo-icon {
        color: rgb(1, 73, 197);
        fill: rgb(1, 73, 197);
        width: 60px;
        height: 60px;
    }

    .demo-link {
        color: #0149c5 !important;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;    
        font-size: 1rem;
        margin-bottom: 0.75rem;
    }

    .demo-description {
        margin-bottom: 1rem;
        color: #767676;
        font-family: 'Rubik', sans-serif;
        font-size: 1rem;
        font-style: normal;
        line-height: 1.6;
        text-align: center;
    }

    .tutor-video {
        font-size: 20px;
        font-weight: 500;
        color: #0149c5 !important;
    }
}