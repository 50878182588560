
.account-page {
  font-weight: 500;

  .btn-blue {
    max-width: 132px;
    padding: 6px 18px;
  }

  .disabled-btn {
    &:hover {
      cursor: not-allowed;
    }
  }

  .cancel-subscription-dialog {
    .sub-plan-list {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .plan-item {
        border-radius: 10px;
        background-color: #F0F6FF;
        padding: 18px;
        width: 44%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .btn-subscribe {
          font-size: 16px !important;
          margin: 0 auto;
          margin-top: 24px;
          padding: 6px 18px;
        }
      }

      .price {
        margin-bottom: 12px;

        .plan-title{
          margin-bottom: 24px;

          &:first-child {
            font-size: 18px;
            font-weight: 600;
          }
          hr {
            width: 15%;
            height: 1px;
            background-color: #508BF4 ;
          }
        }

        span {
          font-size: 24px;
          font-weight: bold;

          &:first-child {
            margin-right: 4px;
            color: #22395B;
          }
        }
      }
    }
  }
  
  .page-content {
    color: #22395B;
    display: flex;
    justify-content: space-between;
    // margin-top: 48px;

    .left {
      border-radius: 10px;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.39);
      background-color: #fff;
      max-width: 400px;
      width: 28%;
      margin-right: 24px;
      padding: 24px;
  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      .menu {
        text-align: center;
        .menu-logo {
          width: 116px;
          height: 116px;
          margin: 0 auto;
  
          img {
            max-width: 100%;
          }
        }
  
        .menu-title {
          font-size: 24px;
          font-weight: bold;
          margin: 36px 0px;
        }
  
        .menu-item {
          font-size: 20px;
          font-weight: 500;
          margin: 12px 0px;
        }
      }
    }
  
    .right {
      border-radius: 10px;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.39);
      background-color: #fff;
      max-width: 995px;
      width: 100%;
      height: 100%;
      padding-top: 48px;
      padding-bottom: 48px;

      .tab-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 575px;
        width: 100%;
        margin: 0 auto;
        border-bottom: 2px solid #F0F6FF;

        .tab-item {
          font-size: 20px;
          font-weight: 500;
          padding: 6px 0px;
          margin-bottom: -2px;
          margin-right: 12px;
          margin-left: 12px;
          transition: all 0.3s ease-in-out;
          text-align: center;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            cursor: pointer;
          }

          border-bottom: 2px solid #F0F6FF;
        }

        .tab-item__active {
          border-bottom: 2px solid #3377EB !important;
        }
      }

      .tab-container {
        .tab-content-membership {
          // max-width: 575px;
          width: 100%;
          margin: 0 auto;

          .plan-list {
            // width: 100%;
            // text-align: center;
            // padding: 24px 0px;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            align-items: center;

            .plan-item {
              border-radius: 10px;
              background-color: #F0F6FF;
              padding: 18px;
              width: 25%;
              min-height: 450px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .btn-blue {
                font-size: 16px !important;
                margin: 0 auto;
                margin-top: 24px;
              }

              .cancel-btn {
                font-size: 16px !important;
                margin: 0 auto;
                margin-top: 24px;
                padding: 6px 18px;
                text-align: center;
              }
            }

            .subscribed-plan {
              background-color: #d6dce4;
            }

            .price {

              .plan-title{
                margin-bottom: 24px;

                &:first-child {
                  font-size: 18px;
                  font-weight: 600;
                }
                hr {
                  width: 15%;
                  height: 1px;
                  background-color: #508BF4 ;
                }
              }

              .membership-box {
                p {
                  margin-top: 10px;
                  font-size: 15px;
                  font-weight: 400;
                  text-align: left;
                  line-height: 18px;
                  min-height: 50px;
                }

                ul {
                  padding-left: 10px;
                  text-align: left;
                  line-height: 25px;
                  margin-bottom: 0px;
                  // min-height: 180px;
                }
              }

              span {
                font-size: 24px;
                font-weight: bold;

                &:first-child {
                  margin-right: 4px;
                  color: #22395B;
                }
              }
            }
          }

          .link-btn {
            font-size: 16px;
            font-weight: 500;
            color: #3377EB;
            text-decoration: underline;
            margin-top: 24px;

            &:hover {
              cursor: pointer;
            }
          }

          .tab-content-title {
            font-size: 18px;
            margin: 36px 0px;
            text-align: center;
          }

          .membership-basic {
            width: 80%;
            margin: auto;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .basic-line {
              display: flex;
              align-items: start;
              justify-content: center;
              gap: 20px;
              .basic-individual {
                width: 50%;
                font-size: 16px;
              }
            }
          }

          .two-column-list {

            display: grid;
            grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
            gap: 10px; /* Gap between items */
            // list-style: none; /* Remove default list styles */
            // width: 60%;
            max-width: 500px;
            margin: auto;
            margin-bottom: 30px;
          }
          
          .two-column-list li {
            padding: 5px;
            // border: 1px solid #ccc;
          }

          .pricing-content {
            border-radius: 10px;
            background-color: #F0F6FF;
            width: 100%;
            text-align: center;
            padding: 36px;

            .pricing-title {
              font-size: 20px;
              color: #718DBC;
              padding: 0;
              margin-bottom: 12px;
            }

            .price-text {
              margin-bottom: 24px;
              .big-text {
                font-size: 24px;
                font-weight: bold;
              }

              .small-text {
                font-size: 18px;
                font-weight: bold;
              }
            }

            .prices {
              font-size: 18px;
              color: #96A7C4;
              padding: 8px 0px;
              width: 336px;
              margin: 0 auto;
            }

            .price-line {
              width: 336px;
              margin: 0 auto;
              padding: 8px 0px;
              color: #96A7C4;
              font-size: 18px;
              border-top: 1px solid #96A7C4;
            }

            .plus-minus {
              width: 148px;
              margin: 24px auto;
              display: flex;
              justify-content: space-between;

              .minus {
                background-color: #3377EB;
                color: #fff;
                border-radius: 50%;

                &:hover {
                  cursor: pointer;
                }

                svg {
                  transition: all 0.3s ease-in-out;

                  &:hover {
                    transform: rotate(360deg);
                  }
                }
              }

              .count {

              }

              .plus {
                background-color: #3377EB;
                color: #fff;
                border-radius: 50%;

                &:hover {
                  cursor: pointer;
                }

                svg {
                  transition: all 0.3s ease-in-out;

                  &:hover {
                    transform: rotate(360deg);
                  }
                }
              }
            }

            .btn-blue {
              max-width: 132px;
              margin: 0 auto;
              padding: 6px 18px;
            }
          }
        }

        .tab-content-upload {
          max-width: 575px;
          width: 100%;
          margin: 0 auto;
          padding: 48px;

          input {
            visibility: hidden;
          }

          .upload-container {
            border-radius: 10px;
            border: 1px solid #DFECFF;
            padding: 48px;
            text-align: center;

            &:hover {
              cursor: pointer;
            }

            .upload-icon {
              margin: 0 auto;
            }

            .upload-text {
              margin: 0 auto;
              font-size: 18px;
              margin: 48px 0px;
            }

            .btn-blue {
              margin: 0 auto;
              max-width: 250px;
            }
          }
        }

        .tab-content-edit {
          max-width: 575px;
          width: 100%;
          margin: 0 auto;
          padding: 48px 0px;

          .edit-title {
            font-size: 18px;
            margin-bottom: 24px;
          }

          .form-container {
            border-bottom: 1px solid #000;
            margin-bottom: 36px;

            .form-input {
              display: flex;
              justify-content: space-between;
              align-items: center;

              input {
                width: 100%;
                border: none;
                font-size: 18px;
                padding: 8px 0px;
                color: #22395B;

                &:focus {
                  outline: none;
                }
              }
            }
          }
        }
      }
    }
  } 
}

@media screen and (max-width: 1201px) {
  .account-page {
    .page-content {
      .left {
        padding: 24px;
      }

      .right {
        padding: 24px;
      }
    }
  }
}

@media screen and (max-width: 1018px) {
  .account-page {
    .page-content {
      display: block;
      .left {
        max-width: 100%;
        width: 100%;
        margin-bottom: 48px;
      }

      .right {
        .plan-list {
          flex-direction: column;

          .plan-item {
            width: 100% !important;
            margin: 12px 0px;
            min-height: 150px;
            .membership-box {
              ul {
                padding-left: 30px !important;
                min-height: 200px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .account-page {
    

    .cancel-subscription-dialog {
      .sub-plan-list {
        display: block;
        
        .plan-item {
          width: 100%;
          margin: 12px 0px;
          min-height: 150px;
        }
      }
    }
  }
}

.text-blue {
  color: #22395B;
}

.paymentForm-input {
  color: #22395B;
  font-size: 18px;
  padding: 6px 12px;
  border: none;
  border-bottom: 1px solid #707070;
  width: 100%;
}