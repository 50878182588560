a {
    color: $page-color !important;
    text-decoration: none;

    &:hover {
        text-decoration: none !important;
    }
}

.auth-page {
    width: 100%;
    // height: calc(100vh);
    // overflow: hidden;
    // background-color: #F0F6FF;
    position: relative;
    color: $page-color;
    font-weight: 500;

    .bubbly-button {
        width: 100%;
        padding: 8px;
        height: auto !important;
    }

    .line-float {
        width: 58px;
        height: 111px;
        position: absolute;
        top: 44px;
        left: 0;
    }

    .curve-float {
        width: 116px;
        height: 109px;
        position: absolute;
        top: 400px;
        right: 0;
    }

    .moon-float {
        width: 65%;
        position: absolute;
        top: 210px;
        left: -280px;
        animation: moon-animation 8s ease-in-out infinite;
    }

    @keyframes moon-animation {
        0% {
            transform: scaleX(1) scaleY(1.04);
        }

        25% {
            transform: scaleX(1.2) scaleY(1);
        }

        50% {
            transform: scaleX(1) scaleY(1.04);
        }

        75% {
            transform: scaleX(1.2) scaleY(1);
        }

        100% {
            transform: scaleX(1) scaleY(1.04);
        }
    }

    .licence {
        display: flex;
        justify-content: flex-end;
        font-size: 15px;
        color: $page-color;

        .marked-text {
            color: $page-color-primary;
        }
    }

    .img-responsive {
        max-width: 100%;
    }

    .content {
        // padding: 90px 115px 50px 115px;
        padding: 50px 115px;
        position: relative;
        height: 100%;

        .content-container {
            height: 100%;
        }

        .left {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 30px;
        }

        .right {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 30px;
        }

        .title-container {
            .page-title-line {
                width: 70px;
                height: 3px;
                background-color: white;
                margin-right: 20px;
            }
        
            .page-title {
                font-size: 25px;
            }
        }

        .sub-title {
            font-size: 70px;
            line-height: 80px;
            font-weight: 600;
        }

        .page-image {
            // margin-top: 20px;
            width: 100%;
        }

        .terms-policy {
            font-size: 15px;
            font-weight: 500;
            // margin-top: 20px;
            position: absolute;
            left: 0;
            bottom: 0;

            a:first-child {
                margin-right: 20px;
            }
        }

        .mobile-form {
            display: none;
        }

        .register-form {
            margin-top: 10px !important;
        }

        .auth-form {
            width: 488px;
            background-color: #fff;
            border-radius: 20px;
            // margin-top: 100px;
            margin-left: 40px;
            padding: 46px;

            .top {
                font-size: 25px;

                div:first-child {
                    margin-right: 10px;
                }
            }

            .middle {
                margin-top: 50px;

                .forgot-password {
                    font-size: 15px;
                    // border-bottom: 0.3px solid #000;

                    &:hover {
                        cursor: pointer;
                        color: #3366CC !important;
                    }
                }

                .middle-title {
                    font-size: 20px;
                    text-align: center;
                }
            }

            .bottom {
                font-size: 20px;

                div:last-child {
                    width: 45%;
                    background-color: $page-color-primary;
                    color: #fff;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    border-radius: 10px;
                    height: 56px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        transform: translateY(-2px);
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
                    }
                }

                a {
                    width: 45%;
                    text-align: center;
                }
            }

            .tos {
                font-size: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                .get-demo {
                    font-size: 12px;
                    border-radius: 20px;
                    border: 1px solid $page-color-primary;
                    padding: 4px 8px;
                    min-width: 80px;
                    text-align: center;
                }

                a:hover {
                    cursor: pointer;
                    color: #3366CC !important;
                }
            }

            .forgot-tos {
                justify-content: center;
            }
        }
    }
    
}

@media screen and (max-width: 1660px) {
    .auth-page {
        .content {
            padding: 65px 65px 30px 65px;
        }
    }
}

@media screen and (max-width: 1535px) {
    .auth-page {
        .content {
            padding: 35px 35px 30px 35px;
        }
    }
}

@media screen and (max-width: 1010px) {
    .auth-page {
        .content {
            .auth-form {
                padding: 25px;
            }
        }
    }
}

@media screen and (max-width: 899px) {
    .auth-page {
        height: auto;
        
        .content {
            .auth-form {
                display: none;
            }

            .mobile-form {
                display: block;
                width: 100%;
                margin-top: 40px;
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 40px;
            }

            .sub-title {
                font-size: 50px;
                line-height: 60px;
            }

            .page-image {
                margin-top: 0;
                margin-bottom: 50px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .auth-page {
        .content {
            .licence {
                width: 100%;
                justify-content: center !important;
            }

            .right {
                margin-top: 40px;
            }

            .terms-policy {
                width: 100%;
                justify-content: center !important;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .auth-page {
        .content {
            padding: 25px 10px; 

            .auth-form {
                .middle {
                    margin-top: 30px;
                }
            }

            .title-container {
                .page-title-line {
                    width: 30px;
                }

                .page-title {
                    font-size: 20px;
                }
            }

            .sub-title {
                font-size: 40px;
                line-height: 50px;
            }

            .auth-form {
                .bottom {
                    font-size: 16px;
                }
            }
        }
    }
}