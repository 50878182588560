@import "./assets/customscss/layout.scss";
@import "./assets/customscss/card.scss";
@import "./assets/customscss/customBtn.scss";
@import "./assets/customscss/home.scss";
@import "./assets/customscss/auth.scss";
@import "./assets/customscss/inputform.scss";
@import "./assets/customscss/selectform.scss";
@import "./assets/customscss/employee.scss";
@import "./assets/customscss/commission.scss";
@import "./assets/customscss/table.scss";
@import "./assets/customscss/receiveFunds.scss";
@import "./assets/customscss/users.scss";
@import "./assets/customscss/account.scss";
@import "./assets/customscss/paynow.scss";
@import "./assets/customscss/payrollHistory.scss";
@import "./assets/customscss/payrollReport.scss";
@import "./assets/customscss/grossSales.scss";
@import "./assets/customscss/expenseReport.scss";
@import "./assets/customscss/employeeAccount.scss";
@import "./assets/customscss/customerReport.scss";
@import "./assets/customscss/demo.scss";

body {
  position: relative;
  height: 100%;
}

#root {
  height: 100%;
}

/* Global */
html {
  scroll-behavior: smooth;
}

.navbar .navbar-brand {
  font-size: 1em !important;
  overflow: hidden;
}

.navbar .navbar-brand img {
  width: 40px;
  height: 40px;
  margin-right: 10px !important;
}

li.nav-item .shopping_cart {
  padding-left: 5px !important;
}

.navbar .navbar-nav .nav-link:not(.btn) {
  font-size: 12px !important;
}

.navbar .btn-logout {
  font-size: 1em !important;
  color: white;
  background-color: #3a6eb4 !important;
  margin: 0 0 0 10px !important;
}

.title {
  padding-top: 50px !important;
}

.justify-content-right {
  justify-content: flex-end;
}

.home {
  // padding: 70px 0;
  position: relative;
  // background: #FFFFFF;
}

.panel {
  text-align: center;
}

.title-icon {
  padding-right: 10px !important;
  vertical-align: middle;
}

.form-control {
  font-size: 1em !important;
}

.tos {
  color: #2c2c2c !important;
  font-size: 1.1em !important;
  font-weight: 400 !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent !important;
  opacity: 1;
}

.form-control:focus,
.form-control[readonly] {
  background-color: transparent !important;
  opacity: 1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

label {
  font-size: 1.3em !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-color {
  background-color: #3a6eb4 !important;
}

.btn-cpp {
  font-size: 1em !important;
  color: white;
  background-color: #3a6eb4 !important;
}

.default-color {
  color: #3a6eb4 !important;
}

.red-alert-color {
  background-color: #a62e1b !important;
}

.grey-alert-color {
  background-color: #648292 !important;
}

.font-cpp-small {
  font-size: 1em !important;
}

.font-cpp {
  font-size: 1.2em !important;
}

.font-cpp-big {
  font-size: 1.4em !important;
}

.nav-pills.nav-pills-info .nav-item .nav-link.active {
  background-color: #3a6eb4 !important;
}

@media screen and (max-width: 767.98px) {
  .mobile-collapse {
    display: none;
  }
}

.confirm-dialog {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #89a4ce;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

@media screen and (max-width: 767.98px) {
  .confirm-dialog {
    width: 300px;
  }
}

/* Register/ Login */

.login-page .card-login {
  border-radius: 0.25rem;
  padding-bottom: 0.7rem;
  min-width: 90% !important;
}

.login-page .btn-login {
  font-size: 1.4em !important;
  font-weight: 500 !important;
  background-color: #3a6eb4 !important;
}

.login-page input {
  font-size: 1.2em !important;
}

.login-page .link {
  font-size: 1.1em !important;
  color: #3a6eb4 !important;
  text-decoration: none;
  font-weight: 600 !important;
}

.login-page .card-signup {
  padding: 0 1.25rem 1.25rem 1.25rem !important;
}

.login-page .header-filter {
  /* background-image: linear-gradient(to right top, #0488ec, #0073dd, #005dcd, #0047bc, #0430a9); */
  /* background-image: linear-gradient(to right, #0488ec, #0073dd, #005dcd, #0047bc, #0430a9); */
  /* background-image: linear-gradient(to left, #0488ec, #0073dd, #005dcd, #0047bc, #0430a9); */
  /* background-image: linear-gradient(to bottom, #0488ec, #0073dd, #005dcd, #0047bc, #0430a9); */
  /* background-image: linear-gradient(to top, #0488ec, #0073dd, #005dcd, #0047bc, #0430a9); */
  /* background-image: linear-gradient(to left bottom, #0488ec, #0073dd, #005dcd, #0047bc, #0430a9); */
  /* background: rgb(4,136,236);
  background: linear-gradient(90deg, rgba(4,136,236,1) 0%, rgba(4,48,169,1) 100%); */
  background-image: linear-gradient(to right top, #00abf9, #00a2f6, #0099f3, #0090ef, #0586eb);
  position: relative;
}

.login-page .divider-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20vw;
}

.login-page .divider-top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 30vw;
}

.login-page .brand img {
  width: 80px;
  height: 80px;
}

@media screen and (max-width: 991.98px) {
  .login-page .brand img {
    width: 60px;
    height: 60px;
  }
}

.login-page .card-login {
  padding-top: 20px !important;
}

.login-page .card-title {
  margin-top: 15px;
  line-height: 1.25em;
  color: black;
  margin-bottom: 20px;
}

.login-page .companyName {
  color: black;
}

.login-page .reset-password-label {
  color: black;
}

.login-page .companyName small {
  font-size: 100%;
}

.red-text {
  font-size: 14px !important;
  color: red !important;
  font-weight: 500;
  display: block;
}

.login-page .link-signup {
  font-size: 18px !important;
  color: #3a6eb4 !important;
  font-weight: 500 !important;
  text-decoration: none !important;
}
.login-page .link-signup:hover,
.login-page .link-signup:focus {
  border-color: #3a6eb4 !important;
  text-decoration: none !important;
}

.login-page .card-footer {
  padding: 0 1.25rem 1.25rem !important;
}

.login-page .fields-signup {
  margin: auto !important;
}

.login-page .btn-create-reset {
  display: flex !important;
  justify-content: space-between;
}

/* Home Page */

.home-page .btn-modules {
  width: 100%;
  height: 7vw;
  font-size: 1.3em !important;
  color: white;
  background-color: #3a6eb4 !important;
  text-align: center;
  min-width: 200px;
  min-height: 100px;
  transition: all 0.1s ease 0s;
  position: relative;
}

.home-page .step {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 25px;
  width: 50px;
  border-radius: 150px 150px 0 0;
  background-color: #3a6eb4;
}

.home-page .arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px !important;
}

.home-page .arrow {
  color: #55acee;
  font-size: 60px;
}

.home-page .icon-module {
  position: absolute;
  top: 43%;
  left: 40px;
  z-index: 9999;
  color: white;
  font-size: 1.3em;
  transition: all 0.1s ease 0s;
}

.home-page .btn-modules label {
  font-size: 1.1em !important;
  color: white;
  padding-left: 10px;
}

.home-page .card-stats .icon-big {
  font-size: 3em;
  min-height: 64px;
}

.home-page .card-stats .numbers {
  font-size: 1.2rem;
  text-align: right;
}

.home-page .card .card-title {
  font-weight: 700;
  color: #555;
}

.home-page .card-stats .card-footer {
  text-align: left;
}

.home-page .card-stats .btn-hyperlink {
  margin: 2px 0 0 0 !important;
  padding: 0;
  font-size: 1.2em !important;
  font-weight: 600;
}

.btn-hyperlink-big {
  font-size: 1.5em !important;
  font-weight: 800 !important;
}

.home-page .card-stats .ct-chart {
  padding: 15px;
  margin: 0 15px;
  margin-top: -20px;
  border-radius: 3px;
}

.home-page .card-stats .ct-chart-gross {
  background: linear-gradient(60deg, #66bb6a, #43a047);
}

.home-page .card-stats .ct-chart-payroll {
  background: linear-gradient(60deg, #ffa726, #fb8c00);
}

.home-page .card-stats .ct-chart-expenses {
  background: linear-gradient(60deg, #ef5350, #e53935);
}

.home-page .ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: hsla(0, 0%, 100%, 0.8);
}

.home-page .ct-grid {
  stroke: hsla(0, 0%, 100%, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.home-page .ct-label {
  color: hsla(0, 0%, 100%, 0.7);
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}

.home-page .card-stats {
  text-align: left;
}

.home-page .card-stats .card-category {
  text-align: left;
}

.home-page .card-stats .card-footer-section {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1300px) {
  .home-page .arrow {
    display: none !important;
  }
}

@media screen and (max-width: 1300px) and (min-width: 992px) {
  .home-page .btn-container i {
    display: none !important;
  }

  .home-page .btn-modules label {
    padding-left: 0;
  }
}

.account-page .nav,
.commission-pay .nav {
  justify-content: center !important;
  margin-top: 15px !important;
}

.account-page .tab-space {
  padding: 50px 0 50px 0px !important;
}

.account-page .card-pricing ul {
  max-width: 300px !important;
}

.btn-add {
  font-size: 1.2em !important;
  font-weight: 500 !important;
  background-color: #3a6eb4 !important;
  margin-top: 30px !important;
}

.home-page .btn-container {
  transition: all 0.1s ease 0;
}

.home-page .btn-container:hover {
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -o-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

/* Commission Pay */

.commission-pay-page .nav {
  justify-content: center !important;
  margin-top: 15px !important;
}

.commission-pay-page .form-group,
.receive-funds-page .form-group,
.expense-page .form-group {
  text-align: left;
}

.commission-pay-page .form-group label,
.receive-funds-page .form-group label,
.expense-page .form-group label {
  margin-left: 18px !important;
}

.commission-pay-page .card-raised,
.receive-funds-page .card-pricing {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1) !important;
}

.commission-pay-page .employee-payout-label {
  margin-left: 30px !important;
  font-size: 1.3em;
  color: #3a6eb4;
  font-weight: 600;
}

.commission-pay-page .form-group .employee-label {
  margin-left: 18px !important;
}

.commission-pay-page .btn-save-commission {
  justify-content: space-around;
  margin-top: 14px;
}

.react-select .react-select__control .react-select__value-container--has-value .react-select__single-value {
  color: #2c2c2c !important;
}

.react-select .react-select__control .react-select__value-container,
.react-select .react-select__control .react-select__indicators {
  padding: 2px 15px !important;
  font-size: 1.1em !important;
}

.react-select .react-select__control .react-select__placeholder {
  color: #2c2c2c !important;
}

/* .commission-pay-page .description {
  color: #2c2c2c !important;
  font-size: 1.1em !important;
  font-weight: 400 !important;
} */

.commission-pay-page .modal-dialog {
  max-width: 80% !important;
  margin: 5rem auto;
}

.commission-pay-page .modal-dialog .answerPane {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.commission-pay-page .card-body .title {
  margin-bottom: 25px !important;
}

.commission-pay-page .btn-add-employee {
  font-size: 0.6em !important;
  color: white;
  background-color: #89a4ce !important;
}

.commission-pay-page .table-card {
  text-align: center;
}

@media screen and (min-width: 992px) {
  .commission-pay-page .modal-dialog {
    max-width: 70% !important;
  }
}

@media screen and (max-width: 600px) {
  .commission-pay-page .modal-dialog {
    max-width: 90% !important;
  }
}

/* Receive Funds */
.receive-funds-page .btn-post {
  justify-content: space-around;
  margin-top: 14px;
}

/* Pay Now */
.pay-now-page .card {
  padding: 15px 0px;
}

.pay-now-page .card-body .data-info {
  text-align: center;
}

.pay-now-page .card-body .expense-info {
  text-align: center;
  color: #ea4c89;
}

.pay-now-page .card-body .category {
  text-align: center;
}

.pay-now-page .card-body .transactions {
  text-align: center;
  color: #3a6eb4 !important;
  font-weight: 500;
}

.pay-now-page .card-body .btn-icon {
  margin: -5px 0 0 0 !important;
}

.pay-now-page .card-body [class*="col-"] {
  border-right: 1px solid #e3e3e3;
}

.pay-now-page .card-body [class*="col-"]:last-child {
  border: 0;
}

.btn-float {
  position: fixed;
  bottom: 25px;
  width: 100%;
  justify-content: center;
  background-color: transparent;
  padding-left: 30px;
}

.pay-now-page .form-control {
  margin-top: -6px;
  font-size: 1em !important;
  text-align: center;
}

/* Pay Report Page */
.pay-report-page .form-control,
.gross-sales-page .form-control,
.payroll-history-page .form-control,
.expense-report-page .form-control {
  text-align: center;
  margin-top: 10px;
}

/* Payroll History Page */

.payroll-history-page .card-body .transactions {
  text-align: center;
  color: #3a6eb4 !important;
  font-weight: 500;
}

.payroll-history-page .payroll-history [class*="col-"] {
  border-right: 1px solid #e3e3e3;
}

.payroll-history-page .payroll-history [class*="col-"]:last-child {
  border: 0;
}

/* Account Page */
.card-pricing h5.category,
.h5.category {
  font-weight: 400;
  color: #2c2c2c !important;
}

.card-pricing a {
  font-size: 1em !important;
  color: white;
  background-color: #3a6eb4 !important;
}

/* Modal */
.modal-confirmation {
  text-align: center;
}

.modal-confirmation .modal-title h4 {
  color: white;
  background-color: #3a6eb4 !important;
  margin: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (min-width: 1201px) {
  .modal-confirmation .modal-dialog {
    max-width: 50% !important;
  }
}

@media screen and (max-width: 1200px) {
  .modal-confirmation .modal-dialog {
    max-width: 60% !important;
  }

  .navbar .btn-logout {
    margin-top: 10px;
  }
}

@media screen and (max-width: 992px) {
  .modal-confirmation .modal-dialog {
    max-width: 70% !important;
  }
}

@media screen and (max-width: 600px) {
  .commission-pay-page .modal-dialog {
    max-width: 90% !important;
  }
}

/* Expense Page */
.expense-page .dropzone {
  width: 100%;
  padding: 10px !important;
  border: 2px dotted #3a6eb4;
}

.expense-page .upload-icon {
  width: 80px;
  height: 90px;
  background: url(../src/assets/img/Upload-PNG-Image-File.png) no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.expense-page .list-group-item-success {
  color: white;
  background-color: #3a6eb4 !important;
}

.expense-page,
.file-display-container {
  width: 100%;
  text-align: left !important;
}

.expense-page .file-status-bar {
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
}

.expense-page .file-status-bar > div {
  overflow: hidden;
}

.expense-page .file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #3a6eb4;
  text-transform: uppercase;
  left: 5px;
}

.expense-page .file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #3a6eb4;
}

.expense-page .file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.expense-page .file-error-message {
  color: red;
}

.expense-page .file-type-logo {
  width: 50px;
  height: 50px;
  background: url(../src/assets/img/generic.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
}

.expense-page .file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

// .expense-page .file-remove  {
//   position: absolute;
//   top: 20px;
//   right: 10px;
//   line-height: 15px;
//   cursor: pointer;
//   color: red;
//   margin-right: -10px;
// }

@media screen and (max-width: 992px) {
  label {
    font-size: 1em !important;
  }
}

/* Expense Report */
.expense-report-page #table-expense .btn {
  background-color: transparent !important;
  font-size: 1.2em !important;
}

.expense-report-page #table-expense th {
  font-size: 1.2em !important;
  text-transform: capitalize;
  font-weight: 700;
  color: #9a9a9a;
}

.expense-report-page #table-expense td h5 {
  margin-bottom: 0 !important;
  text-transform: capitalize;
}

.expense-report-page #table-expense .transaction {
  text-align: left;
  font-size: 1em;
}

@media screen and (max-width: 1200px) {
  .expense-report-page #table-expense .mobile {
    display: none;
  }
}

.expense-report-page #table-expense i {
  background-color: transparent !important;
  font-size: 1.2em !important;
  margin-top: 5px;
}

.expense-report-page,
.file-display-container {
  width: 100%;
  text-align: left !important;
}

.expense-report-page .file-status-bar,
.pay-now-page .file-status-bar {
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 30px;
  height: 30px;
}

.expense-report-page .file-status-bar > div,
.pay-now-page .file-status-bar > div {
  overflow: hidden;
}

.expense-report-page .file-type,
.pay-now-page .file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 9px;
  font-weight: 600;
  line-height: 12px;
  margin-top: 14px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #3a6eb4;
  text-transform: uppercase;
  left: 5px;
}

.expense-report-page .file-name,
.pay-now-page .file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 40px;
  color: #3a6eb4 !important;
}

.expense-report-page .file-error,
.pay-now-page .file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.expense-report-page .file-error-message,
.pay-now-page .file-error-message {
  color: red;
}

.expense-report-page .file-type-logo,
.pay-now-page .file-type-logo {
  width: 30px;
  height: 30px;
  background: url(../src/assets/img/generic.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
}

.expense-report-page .file-size,
.pay-now-page .file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 500;
  font-size: 14px;
}

// .expense-report-page .file-remove, .pay-now-page .file-remove{
//   position: absolute;
//   top: 9px;
//   right: 8px;
//   line-height: 15px;
//   cursor: pointer;
//   color: red;
//   margin-right: 20px;
// }

.login-page .get-demo {
  cursor: pointer;
}

.__floater.__floater__open {
  z-index: 9999 !important;
}

.selected-background-color {
  background-color: rgba(222, 222, 222, 0.3);
}

.btn-edit {
  position: absolute;
  top: 30px;
  right: 27px;
}

.data-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-column label {
  margin-bottom: 0 !important;
}

/* -- BACKGROUND EFFECT -- */

.particle-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: -1;
}

/* -- shapes -- */

.particle {
  position: absolute;
  width: 0;
  height: 0;
  z-index: 1;
}

/* -- SHAPES EFFECT -- */

@-webkit-keyframes particle-animation-1 {
  100% {
    -webkit-transform: translate3d(50vw, 30vh, 152px);
    transform: translate3d(50vw, 30vh, 152px);
  }
}

@keyframes particle-animation-1 {
  100% {
    -webkit-transform: translate3d(50vw, 30vh, 152px);
    transform: translate3d(50vw, 30vh, 152px);
  }
}

.particle:nth-child(1) {
  -webkit-animation: particle-animation-1 60s infinite;
  animation: particle-animation-1 60s infinite;
  opacity: 1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #f6e58d;
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
  -webkit-transform: translate3d(27vw, 23vh, 48px) rotate(360deg);
  transform: translate3d(27vw, 23vh, 48px) rotate(360deg);
}

@-webkit-keyframes particle-animation-2 {
  100% {
    -webkit-transform: translate3d(65vw, 61vh, 32px);
    transform: translate3d(65vw, 61vh, 32px);
  }
}

@keyframes particle-animation-2 {
  100% {
    -webkit-transform: translate3d(65vw, 61vh, 32px);
    transform: translate3d(65vw, 61vh, 32px);
  }
}
.particle:nth-child(2) {
  -webkit-animation: particle-animation-2 60s infinite;
  animation: particle-animation-2 60s infinite;
  opacity: 1;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #ffbe76;
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
  -webkit-transform: translate3d(9vw, 51vh, 10px) rotate(270deg);
  transform: translate3d(9vw, 51vh, 10px) rotate(270deg);
}

@-webkit-keyframes particle-animation-3 {
  100% {
    -webkit-transform: translate3d(79vw, 42vh, 84px);
    transform: translate3d(79vw, 42vh, 84px);
  }
}

@keyframes particle-animation-3 {
  100% {
    -webkit-transform: translate3d(79vw, 42vh, 84px);
    transform: translate3d(79vw, 42vh, 84px);
  }
}

.particle:nth-child(3) {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #ff7979;
  -webkit-animation: particle-animation-3 60s infinite;
  animation: particle-animation-3 60s infinite;
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
  -webkit-transform: translate3d(49vw, 17vh, 43px) rotate(180deg);
  transform: translate3d(49vw, 17vh, 43px) rotate(180deg);
}

@-webkit-keyframes particle-animation-4 {
  100% {
    -webkit-transform: translate3d(22vw, 57vh, 61px);
    transform: translate3d(22vw, 57vh, 61px);
  }
}

@keyframes particle-animation-4 {
  100% {
    -webkit-transform: translate3d(22vw, 57vh, 61px);
    transform: translate3d(22vw, 57vh, 61px);
  }
}
.particle:nth-child(4) {
  -webkit-animation: particle-animation-4 60s infinite;
  animation: particle-animation-4 60s infinite;
  opacity: 1;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 18px solid #badc58;
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  -webkit-transform: translate3d(16vw, 77vh, 3px) rotate(90deg);
  transform: translate3d(16vw, 77vh, 3px) rotate(90deg);
}

@-webkit-keyframes particle-animation-5 {
  100% {
    -webkit-transform: translate3d(86vw, 2vh, 87px);
    transform: translate3d(86vw, 2vh, 87px);
  }
}

@keyframes particle-animation-5 {
  100% {
    -webkit-transform: translate3d(86vw, 2vh, 87px);
    transform: translate3d(86vw, 2vh, 87px);
  }
}
.particle:nth-child(5) {
  -webkit-animation: particle-animation-5 60s infinite;
  animation: particle-animation-5 60s infinite;
  opacity: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid #f9ca24;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  -webkit-transform: translate3d(63vw, 86vh, 10px) rotate(360deg);
  transform: translate3d(63vw, 86vh, 10px) rotate(360deg);
}

@-webkit-keyframes particle-animation-6 {
  100% {
    -webkit-transform: translate3d(2vw, 59vh, 29px);
    transform: translate3d(2vw, 59vh, 29px);
  }
}

@keyframes particle-animation-6 {
  100% {
    -webkit-transform: translate3d(2vw, 59vh, 29px);
    transform: translate3d(2vw, 59vh, 29px);
  }
}
.particle:nth-child(6) {
  -webkit-animation: particle-animation-6 60s infinite;
  animation: particle-animation-6 60s infinite;
  opacity: 1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #f0932b;
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
  -webkit-transform: translate3d(63vw, 9vh, 86px) rotate(270deg);
  transform: translate3d(63vw, 9vh, 86px) rotate(270deg);
}

@-webkit-keyframes particle-animation-7 {
  100% {
    -webkit-transform: translate3d(6vw, 38vh, 14px);
    transform: translate3d(6vw, 38vh, 14px);
  }
}

@keyframes particle-animation-7 {
  100% {
    -webkit-transform: translate3d(6vw, 38vh, 14px);
    transform: translate3d(6vw, 38vh, 14px);
  }
}
.particle:nth-child(7) {
  -webkit-animation: particle-animation-7 60s infinite;
  animation: particle-animation-7 60s infinite;
  opacity: 1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #eb4d4b;
  -webkit-animation-delay: -1.4s;
  animation-delay: -1.4s;
  -webkit-transform: translate3d(84vw, 82vh, 80px) rotate(180deg);
  transform: translate3d(84vw, 82vh, 80px) rotate(180deg);
}

@-webkit-keyframes particle-animation-8 {
  100% {
    -webkit-transform: translate3d(44vw, 90vh, 49px);
    transform: translate3d(44vw, 90vh, 49px);
  }
}

@keyframes particle-animation-8 {
  100% {
    -webkit-transform: translate3d(44vw, 90vh, 49px);
    transform: translate3d(44vw, 90vh, 49px);
  }
}
.particle:nth-child(8) {
  -webkit-animation: particle-animation-8 60s infinite;
  animation: particle-animation-8 60s infinite;
  opacity: 1;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #6ab04c;
  -webkit-animation-delay: -1.6s;
  animation-delay: -1.6s;
  -webkit-transform: translate3d(68vw, 14vh, 72px) rotate(90deg);
  transform: translate3d(68vw, 14vh, 72px) rotate(90deg);
}

@-webkit-keyframes particle-animation-9 {
  100% {
    -webkit-transform: translate3d(75vw, 71vh, 21px);
    transform: translate3d(75vw, 71vh, 21px);
  }
}

@keyframes particle-animation-9 {
  100% {
    -webkit-transform: translate3d(75vw, 71vh, 21px);
    transform: translate3d(75vw, 71vh, 21px);
  }
}
.particle:nth-child(9) {
  -webkit-animation: particle-animation-9 60s infinite;
  animation: particle-animation-9 60s infinite;
  opacity: 1;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #7ed6df;
  -webkit-animation-delay: -1.8s;
  animation-delay: -1.8s;
  -webkit-transform: translate3d(24vw, 48vh, 40px) rotate(360deg);
  transform: translate3d(24vw, 48vh, 40px) rotate(360deg);
}

@-webkit-keyframes particle-animation-10 {
  100% {
    -webkit-transform: translate3d(25vw, 51vh, 61px);
    transform: translate3d(25vw, 51vh, 61px);
  }
}

@keyframes particle-animation-10 {
  100% {
    -webkit-transform: translate3d(25vw, 51vh, 61px);
    transform: translate3d(25vw, 51vh, 61px);
  }
}
.particle:nth-child(10) {
  -webkit-animation: particle-animation-10 60s infinite;
  animation: particle-animation-10 60s infinite;
  opacity: 1;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #e056fd;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
  -webkit-transform: translate3d(29vw, 59vh, 54px) rotate(270deg);
  transform: translate3d(29vw, 59vh, 54px) rotate(270deg);
}

@-webkit-keyframes particle-animation-11 {
  100% {
    -webkit-transform: translate3d(64vw, 26vh, 98px);
    transform: translate3d(64vw, 26vh, 98px);
  }
}

@keyframes particle-animation-11 {
  100% {
    -webkit-transform: translate3d(64vw, 26vh, 98px);
    transform: translate3d(64vw, 26vh, 98px);
  }
}
.particle:nth-child(11) {
  -webkit-animation: particle-animation-11 60s infinite;
  animation: particle-animation-11 60s infinite;
  opacity: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid #686de0;
  -webkit-animation-delay: -2.2s;
  animation-delay: -2.2s;
  -webkit-transform: translate3d(49vw, 86vh, 28px) rotate(180deg);
  transform: translate3d(49vw, 86vh, 28px) rotate(180deg);
}

@-webkit-keyframes particle-animation-12 {
  100% {
    -webkit-transform: translate3d(38vw, 47vh, 35px);
    transform: translate3d(38vw, 47vh, 35px);
  }
}

@keyframes particle-animation-12 {
  100% {
    -webkit-transform: translate3d(38vw, 47vh, 35px);
    transform: translate3d(38vw, 47vh, 35px);
  }
}
.particle:nth-child(12) {
  -webkit-animation: particle-animation-12 60s infinite;
  animation: particle-animation-12 60s infinite;
  opacity: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid #30336b;
  -webkit-animation-delay: -2.4s;
  animation-delay: -2.4s;
  -webkit-transform: translate3d(87vw, 43vh, 21px) rotate(90deg);
  transform: translate3d(87vw, 43vh, 21px) rotate(90deg);
}

@-webkit-keyframes particle-animation-13 {
  100% {
    -webkit-transform: translate3d(12vw, 68vh, 96px);
    transform: translate3d(12vw, 68vh, 96px);
  }
}

@keyframes particle-animation-13 {
  100% {
    -webkit-transform: translate3d(12vw, 68vh, 96px);
    transform: translate3d(12vw, 68vh, 96px);
  }
}
.particle:nth-child(13) {
  -webkit-animation: particle-animation-13 60s infinite;
  animation: particle-animation-13 60s infinite;
  opacity: 1;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 18px solid #22a6b3;
  -webkit-animation-delay: -2.6s;
  animation-delay: -2.6s;
  -webkit-transform: translate3d(43vw, 72vh, 25px) rotate(360deg);
  transform: translate3d(43vw, 72vh, 25px) rotate(360deg);
}

@-webkit-keyframes particle-animation-14 {
  100% {
    -webkit-transform: translate3d(44vw, 47vh, 100px);
    transform: translate3d(44vw, 47vh, 100px);
  }
}

@keyframes particle-animation-14 {
  100% {
    -webkit-transform: translate3d(44vw, 47vh, 100px);
    transform: translate3d(44vw, 47vh, 100px);
  }
}
.particle:nth-child(14) {
  -webkit-animation: particle-animation-14 60s infinite;
  animation: particle-animation-14 60s infinite;
  opacity: 1;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 18px solid #be2edd;
  -webkit-animation-delay: -2.8s;
  animation-delay: -2.8s;
  -webkit-transform: translate3d(2vw, 7vh, 12px) rotate(270deg);
  transform: translate3d(2vw, 7vh, 12px) rotate(270deg);
}

@-webkit-keyframes particle-animation-15 {
  100% {
    -webkit-transform: translate3d(81vw, 40vh, 19px);
    transform: translate3d(81vw, 40vh, 19px);
  }
}

@keyframes particle-animation-15 {
  100% {
    -webkit-transform: translate3d(81vw, 40vh, 19px);
    transform: translate3d(81vw, 40vh, 19px);
  }
}
.particle:nth-child(15) {
  -webkit-animation: particle-animation-15 60s infinite;
  animation: particle-animation-15 60s infinite;
  opacity: 1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #4834d4;
  -webkit-animation-delay: -3s;
  animation-delay: -3s;
  -webkit-transform: translate3d(86vw, 86vh, 81px) rotate(180deg);
  transform: translate3d(86vw, 86vh, 81px) rotate(180deg);
}

@-webkit-keyframes particle-animation-16 {
  100% {
    -webkit-transform: translate3d(24vw, 31vh, 36px);
    transform: translate3d(24vw, 31vh, 36px);
  }
}

@keyframes particle-animation-16 {
  100% {
    -webkit-transform: translate3d(24vw, 31vh, 36px);
    transform: translate3d(24vw, 31vh, 36px);
  }
}
.particle:nth-child(16) {
  -webkit-animation: particle-animation-16 60s infinite;
  animation: particle-animation-16 60s infinite;
  opacity: 1;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 18px solid #130f40;
  -webkit-animation-delay: -3.2s;
  animation-delay: -3.2s;
  -webkit-transform: translate3d(33vw, 38vh, 33px) rotate(90deg);
  transform: translate3d(33vw, 38vh, 33px) rotate(90deg);
}

@-webkit-keyframes particle-animation-17 {
  100% {
    -webkit-transform: translate3d(82vw, 74vh, 83px);
    transform: translate3d(82vw, 74vh, 83px);
  }
}

@keyframes particle-animation-17 {
  100% {
    -webkit-transform: translate3d(82vw, 74vh, 83px);
    transform: translate3d(82vw, 74vh, 83px);
  }
}
.particle:nth-child(17) {
  -webkit-animation: particle-animation-17 60s infinite;
  animation: particle-animation-17 60s infinite;
  opacity: 1;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #f6e58d;
  -webkit-animation-delay: -3.4s;
  animation-delay: -3.4s;
  -webkit-transform: translate3d(68vw, 39vh, 51px) rotate(360deg);
  transform: translate3d(68vw, 39vh, 51px) rotate(360deg);
}

@-webkit-keyframes particle-animation-18 {
  100% {
    -webkit-transform: translate3d(76vw, 12vh, 11px);
    transform: translate3d(76vw, 12vh, 11px);
  }
}

@keyframes particle-animation-18 {
  100% {
    -webkit-transform: translate3d(76vw, 12vh, 11px);
    transform: translate3d(76vw, 12vh, 11px);
  }
}
.particle:nth-child(18) {
  -webkit-animation: particle-animation-18 60s infinite;
  animation: particle-animation-18 60s infinite;
  opacity: 1;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #ffbe76;
  -webkit-animation-delay: -3.6s;
  animation-delay: -3.6s;
  -webkit-transform: translate3d(72vw, 47vh, 4px) rotate(270deg);
  transform: translate3d(72vw, 47vh, 4px) rotate(270deg);
}

@-webkit-keyframes particle-animation-19 {
  100% {
    -webkit-transform: translate3d(80vw, 49vh, 65px);
    transform: translate3d(80vw, 49vh, 65px);
  }
}

@keyframes particle-animation-19 {
  100% {
    -webkit-transform: translate3d(80vw, 49vh, 65px);
    transform: translate3d(80vw, 49vh, 65px);
  }
}
.particle:nth-child(19) {
  -webkit-animation: particle-animation-19 60s infinite;
  animation: particle-animation-19 60s infinite;
  opacity: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid #ff7979;
  -webkit-animation-delay: -3.8s;
  animation-delay: -3.8s;
  -webkit-transform: translate3d(19vw, 4vh, 36px) rotate(180deg);
  transform: translate3d(19vw, 4vh, 36px) rotate(180deg);
}

@-webkit-keyframes particle-animation-20 {
  100% {
    -webkit-transform: translate3d(9vw, 59vh, 70px);
    transform: translate3d(9vw, 59vh, 70px);
  }
}

@keyframes particle-animation-20 {
  100% {
    -webkit-transform: translate3d(9vw, 59vh, 70px);
    transform: translate3d(9vw, 59vh, 70px);
  }
}
.particle:nth-child(20) {
  -webkit-animation: particle-animation-20 60s infinite;
  animation: particle-animation-20 60s infinite;
  opacity: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid #badc58;
  -webkit-animation-delay: -4s;
  animation-delay: -4s;
  -webkit-transform: translate3d(43vw, 49vh, 23px) rotate(90deg);
  transform: translate3d(43vw, 49vh, 23px) rotate(90deg);
}

@-webkit-keyframes particle-animation-21 {
  100% {
    -webkit-transform: translate3d(48vw, 15vh, 12px);
    transform: translate3d(48vw, 15vh, 12px);
  }
}

@keyframes particle-animation-21 {
  100% {
    -webkit-transform: translate3d(48vw, 15vh, 12px);
    transform: translate3d(48vw, 15vh, 12px);
  }
}
.particle:nth-child(21) {
  -webkit-animation: particle-animation-21 60s infinite;
  animation: particle-animation-21 60s infinite;
  opacity: 1;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #f9ca24;
  -webkit-animation-delay: -4.2s;
  animation-delay: -4.2s;
  -webkit-transform: translate3d(82vw, 54vh, 21px) rotate(360deg);
  transform: translate3d(82vw, 54vh, 21px) rotate(360deg);
}

@-webkit-keyframes particle-animation-22 {
  100% {
    -webkit-transform: translate3d(85vw, 4vh, 63px);
    transform: translate3d(85vw, 4vh, 63px);
  }
}

@keyframes particle-animation-22 {
  100% {
    -webkit-transform: translate3d(85vw, 4vh, 63px);
    transform: translate3d(85vw, 4vh, 63px);
  }
}
.particle:nth-child(22) {
  -webkit-animation: particle-animation-22 60s infinite;
  animation: particle-animation-22 60s infinite;
  opacity: 1;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #f0932b;
  -webkit-animation-delay: -4.4s;
  animation-delay: -4.4s;
  -webkit-transform: translate3d(34vw, 17vh, 64px) rotate(270deg);
  transform: translate3d(34vw, 17vh, 64px) rotate(270deg);
}

@-webkit-keyframes particle-animation-23 {
  100% {
    -webkit-transform: translate3d(57vw, 54vh, 58px);
    transform: translate3d(57vw, 54vh, 58px);
  }
}

@keyframes particle-animation-23 {
  100% {
    -webkit-transform: translate3d(57vw, 54vh, 58px);
    transform: translate3d(57vw, 54vh, 58px);
  }
}
.particle:nth-child(23) {
  -webkit-animation: particle-animation-23 60s infinite;
  animation: particle-animation-23 60s infinite;
  opacity: 1;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 18px solid #eb4d4b;
  -webkit-animation-delay: -4.6s;
  animation-delay: -4.6s;
  -webkit-transform: translate3d(26vw, 49vh, 80px) rotate(180deg);
  transform: translate3d(26vw, 49vh, 80px) rotate(180deg);
}

@-webkit-keyframes particle-animation-24 {
  100% {
    -webkit-transform: translate3d(14vw, 48vh, 53px);
    transform: translate3d(14vw, 48vh, 53px);
  }
}

@keyframes particle-animation-24 {
  100% {
    -webkit-transform: translate3d(14vw, 48vh, 53px);
    transform: translate3d(14vw, 48vh, 53px);
  }
}
.particle:nth-child(24) {
  -webkit-animation: particle-animation-24 60s infinite;
  animation: particle-animation-24 60s infinite;
  opacity: 1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #6ab04c;
  -webkit-animation-delay: -4.8s;
  animation-delay: -4.8s;
  -webkit-transform: translate3d(42vw, 66vh, 83px) rotate(90deg);
  transform: translate3d(42vw, 66vh, 83px) rotate(90deg);
}

@-webkit-keyframes particle-animation-25 {
  100% {
    -webkit-transform: translate3d(88vw, 10vh, 8px);
    transform: translate3d(88vw, 10vh, 8px);
  }
}

@keyframes particle-animation-25 {
  100% {
    -webkit-transform: translate3d(88vw, 10vh, 8px);
    transform: translate3d(88vw, 10vh, 8px);
  }
}
.particle:nth-child(25) {
  -webkit-animation: particle-animation-25 60s infinite;
  animation: particle-animation-25 60s infinite;
  opacity: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid #7ed6df;
  -webkit-animation-delay: -5s;
  animation-delay: -5s;
  -webkit-transform: translate3d(63vw, 42vh, 42px) rotate(360deg);
  transform: translate3d(63vw, 42vh, 42px) rotate(360deg);
}

@-webkit-keyframes particle-animation-26 {
  100% {
    -webkit-transform: translate3d(5vw, 54vh, 34px);
    transform: translate3d(5vw, 54vh, 34px);
  }
}

@keyframes particle-animation-26 {
  100% {
    -webkit-transform: translate3d(5vw, 54vh, 34px);
    transform: translate3d(5vw, 54vh, 34px);
  }
}
.particle:nth-child(26) {
  -webkit-animation: particle-animation-26 60s infinite;
  animation: particle-animation-26 60s infinite;
  opacity: 1;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #e056fd;
  -webkit-animation-delay: -5.2s;
  animation-delay: -5.2s;
  -webkit-transform: translate3d(9vw, 61vh, 58px) rotate(270deg);
  transform: translate3d(9vw, 61vh, 58px) rotate(270deg);
}

@-webkit-keyframes particle-animation-27 {
  100% {
    -webkit-transform: translate3d(25vw, 83vh, 70px);
    transform: translate3d(25vw, 83vh, 70px);
  }
}

@keyframes particle-animation-27 {
  100% {
    -webkit-transform: translate3d(25vw, 83vh, 70px);
    transform: translate3d(25vw, 83vh, 70px);
  }
}
.particle:nth-child(27) {
  -webkit-animation: particle-animation-27 60s infinite;
  animation: particle-animation-27 60s infinite;
  opacity: 1;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #686de0;
  -webkit-animation-delay: -5.4s;
  animation-delay: -5.4s;
  -webkit-transform: translate3d(35vw, 32vh, 28px) rotate(180deg);
  transform: translate3d(35vw, 32vh, 28px) rotate(180deg);
}

@-webkit-keyframes particle-animation-28 {
  100% {
    -webkit-transform: translate3d(46vw, 34vh, 83px);
    transform: translate3d(46vw, 34vh, 83px);
  }
}

@keyframes particle-animation-28 {
  100% {
    -webkit-transform: translate3d(46vw, 34vh, 83px);
    transform: translate3d(46vw, 34vh, 83px);
  }
}
.particle:nth-child(28) {
  -webkit-animation: particle-animation-28 60s infinite;
  animation: particle-animation-28 60s infinite;
  opacity: 1;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #30336b;
  -webkit-animation-delay: -5.6s;
  animation-delay: -5.6s;
  -webkit-transform: translate3d(79vw, 27vh, 48px) rotate(90deg);
  transform: translate3d(79vw, 27vh, 48px) rotate(90deg);
}

@-webkit-keyframes particle-animation-29 {
  100% {
    -webkit-transform: translate3d(5vw, 33vh, 45px);
    transform: translate3d(5vw, 33vh, 45px);
  }
}

@keyframes particle-animation-29 {
  100% {
    -webkit-transform: translate3d(5vw, 33vh, 45px);
    transform: translate3d(5vw, 33vh, 45px);
  }
}
.particle:nth-child(29) {
  -webkit-animation: particle-animation-29 60s infinite;
  animation: particle-animation-29 60s infinite;
  opacity: 1;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #22a6b3;
  -webkit-animation-delay: -5.8s;
  animation-delay: -5.8s;
  -webkit-transform: translate3d(78vw, 82vh, 23px) rotate(360deg);
  transform: translate3d(78vw, 82vh, 23px) rotate(360deg);
}

@-webkit-keyframes particle-animation-30 {
  100% {
    -webkit-transform: translate3d(70vw, 29vh, 55px);
    transform: translate3d(70vw, 29vh, 55px);
  }
}

@keyframes particle-animation-30 {
  100% {
    -webkit-transform: translate3d(70vw, 29vh, 55px);
    transform: translate3d(70vw, 29vh, 55px);
  }
}
.particle:nth-child(30) {
  -webkit-animation: particle-animation-30 60s infinite;
  animation: particle-animation-30 60s infinite;
  opacity: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid #be2edd;
  -webkit-animation-delay: -6s;
  animation-delay: -6s;
  -webkit-transform: translate3d(21vw, 65vh, 18px) rotate(270deg);
  transform: translate3d(21vw, 65vh, 18px) rotate(270deg);
}

.area {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -3;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.ripple-background {
  background: #406dff;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -4;
  top: 0;
  left: 0;
  overflow: hidden;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small {
  width: 200px;
  height: 200px;
  right: -100px;
  bottom: -100px;
}

.medium {
  width: 400px;
  height: 400px;
  right: -200px;
  bottom: -200px;
}

.large {
  width: 600px;
  height: 600px;
  right: -300px;
  bottom: -300px;
}

.xlarge {
  width: 800px;
  height: 800px;
  right: -400px;
  bottom: -400px;
}

.xxlarge {
  width: 1000px;
  height: 1000px;
  right: -500px;
  bottom: -500px;
}

.shade1 {
  opacity: 0.2;
}
.shade2 {
  opacity: 0.5;
}

.shade3 {
  opacity: 0.7;
}

.shade4 {
  opacity: 0.8;
}

.shade5 {
  opacity: 0.9;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

.white-text {
  color: white !important;
}

.company-name {
  margin-left: 10px;
  font-weight: bold !important;
  color: #002dc2;
  text-shadow: none;
}

.text-shadow {
  text-shadow: 2px 2px 2px #000000;
}

.footer-row {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

.hero-image-box {
  display: flex;
  width: 100%;
  position: relative;
}

.mockup-img {
  position: absolute;
  z-index: -1;
}

.items-end {
  align-items: end;
}

.guide-text-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 100px 0;
  @media (max-width: 500px) {
    padding: 50px 0;
  }
}

.guide-text {
  color: white;
  font-size: 32px;
  font-weight: 500;
  @media (max-width: 1200px) {
    font-size: 24px;
  }
  @media (max-width: 850px) {
    font-size: 20px;
  }
}

.question-start-btn {
  cursor: pointer;
  border: 1px solid white;
  width: 300px;
  padding: 20px 40px;
  font-size: x-large;
  background-color: #143ec7;
  color: white;
  border-radius: 100px;
  border-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 24px;
    padding: 18px 30px;
  }
  @media (max-width: 850px) {
    font-size: 20px;
    padding: 18px 30px;
  }
  @media (max-width: 360px) {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}

.question-start-btn:hover {
  filter: brightness(1.2);
  transition: all;
}

.start-hero {
  width: 450px;
  height: auto;
  position: absolute;
  bottom: -30px;
  right: 0;
}

.black-hero {
  width: 500px;
  height: auto;
  position: absolute;
  bottom: 0px;
  right: 0;
}
.asian-hero {
  width: 480px;
  height: auto;
  position: absolute;
  bottom: -30px;
  right: 0;
}
.start-hero-container {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: -1;
  @media (max-width: 1200px) {
    height: 700px;
    position: relative;
  }
  @media (max-width: 850px) {
    height: 800px;
    position: relative;
  }
  @media (max-width: 660px) {
    height: 600px;
    position: relative;
  }
  @media (max-width: 450px) {
    height: 500px;
    position: relative;
  }
  @media (max-width: 380px) {
    height: 500px;
    position: relative;
  }
  @media (max-width: 350px) {
    height: 450px;
    position: relative;
  }
  @media (max-width: 300px) {
    height: 380px;
    position: relative;
  }
}

.animation-bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.back-step-arrow-btn {
  padding: 30px 0;
  cursor: pointer;
}
.back-step-arrow-btn:hover {
  margin-left: 5px;
  transition: all;
  transition-duration: 0.5s;
}
.page-number {
  font-size: 32px;
}

.select-btn-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  justify-content: center;
  @media (max-width: 1200px) {
  }
  @media (max-width: 850px) {
    align-items: center;
  }
}

.select-img {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 400px;
  transition: all;
  transition-duration: 0.6s;
  @media (max-width: 1200px) {
    top: -60px;
  }
  @media (max-width: 850px) {
    top: 300px;
    left: 0;
    width: 100%;
  }
}
.active-select-img {
  opacity: 1;
}

.select-btn:hover {
  background-color: #143ec755;
  img {
    transition: all;
    transition-duration: 0.6s;
    opacity: 1;
    z-index: 1;
    z-index: 5;
  }
}
.select-btn {
  color: white;
  font-size: 24px;
  font-weight: 400;
  width: 300px;
  height: 60px;
  border-radius: 50px;
  background-color: #00000000;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.active-select-btn {
  background-color: #143ec7;
  border: 2px solid white;
  font-weight: 600;
  z-index: 4;
}
.active-select-btn:hover {
  background-color: #143ec7;
  cursor: default;
}
.select-box {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
  @media (max-width: 850px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
}

.step-control-btn-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 130px;
  @media (max-width: 850px) {
    margin-top: 100vw;
  }
}

.next-step-btn {
  cursor: pointer;
  background-color: #143ec7;
  color: white;
  font-size: 22px;
  padding: 10px 60px;
  border-radius: 50px;
  border: 2px solid white;
  margin-left: auto;
  @media (max-width: 850px) {
    font-size: 18px;
    padding: 6px 30px;
  }
}
.back-step-btn {
  cursor: pointer;
  background-color: #143ec700;
  color: white;
  font-size: 22px;
  padding: 10px 60px;
  border-radius: 50px;
  border: 2px solid white;
  @media (max-width: 850px) {
    font-size: 18px;
    padding: 6px 30px;
  }
}

.hidden {
  display: none;
}

.question-accordion-box {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  div {
    border-radius: 20px !important;
  }
}
.accordion-item {
  padding: 10px;
  background-color: #ffffffee !important;
  @media (max-width: 560px) {
    padding: 0px;
  }
}

.accordion-title-box {
  div {
    display: flex;
    align-items: center;
    gap: 20px;
    @media (max-width: 560px) {
      gap: 8px;
    }
    p {
      font-size: 32px;
      font-weight: 600;
      color: #1e1e1e;
      @media (max-width: 560px) {
        font-size: 16px;
      }
    }
  }
}
.accordion-desc-box {
  p {
    padding: 10px 0px 10px 50px;
    font-size: 20px;
    color: #333333;
    @media (max-width: 560px) {
      padding: 0px;
      font-size: 14px;
    }
  }
}
.blue-text {
  color: rgb(20, 112, 199);
}

.check-box {
  z-index: 5;
}

.question-end-modal {
  width: 800px;
  height: auto;
  background-color: white;
  border-radius: 40px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  @media (max-width: 920px) {
    width: 90%;
  }
  img {
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    height: 150px;
  }
  p {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
  }
}

.go-app-btn {
  cursor: pointer;
  background-color: #143ec7;
  color: white !important;
  font-size: 22px;
  padding: 10px 60px;
  border-radius: 50px;
  border: 2px solid white;
  margin-top: 40px;
}

// For bug fix
.formula-modal .top .modal-title {
  @media (max-width: 400px) {
    font-size: 12px !important;
  }
}
.formula-modal .top .modal-sub-title {
  @media (max-width: 400px) {
    font-size: 10px !important;
  }
}
.formula-modal .top .com-option {
  @media (max-width: 400px) {
    font-size: 10px !important;
  }
}
.formula-modal .bottom .form-container .form-input {
  @media (max-width: 400px) {
    font-size: 8px !important;
  }
}
.formula-modal .bottom .form-action .save-btn {
  @media (max-width: 400px) {
    font-size: 12px !important;
  }
}
.formula-modal .bottom .form-action .cancel-btn {
  @media (max-width: 400px) {
    font-size: 12px !important;
  }
}

.main-title {
  @media (max-width: 400px) {
    font-size: 30px !important;
  }
}

.guide-text {
  @media (max-width: 500px) {
    color: #002ab3;
  }
}
