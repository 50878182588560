.payroll-page {
  // padding-top: 48px;

  .title {
    padding-top: 0 !important;
  }

  .top-content {
    display: flex;
    justify-content: space-between;

    .check-date {
      height: fit-content;
      width: 23%;
      min-width: 315px;
      margin-right: 24px;
      padding: 16px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.16);

      .title {
        color: #0E283F;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 0.5px solid #96A7C4;
        padding-bottom: 5px;
      }

      .date-range {
        margin: 20px 0px;
        padding: 16px;
        background-color: rgba($color: #F0F6FF, $alpha: 0.6);
        border-radius: 10px;

        .label {
          font-size: 16px;
          color: #96A7C4;
        }

        .calendar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;

          .rdt {
            width: 100%;
            padding-left: 12px;

            input {
              border: none;
              border-bottom: 1px solid #000;
              border-radius: 0;
              margin-top: 0;
            }
          }

          .react-select {
            width: 100%;
            // margin-top: 5px;
            margin-bottom: 0;

            .react-select__control {
              border: none;
              border-bottom: 1px solid #000;
              border-radius: 0;

              .react-select__value-container {
                padding-left: 0 !important;
              }

              .react-select__indicators {
                padding-right: 0 !important;
              }

              .react-select__placeholder {
                font-size: 18px;
              }
            }
          }
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        div {
          padding: 6px 12px;
          width: 48%;
          font-size: 20px;
        }
      }
    }

    .totals-by-employee {
      width: 73%;
      padding: 16px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.16);

      .title {
        color: #0E283F;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 0.5px solid #96A7C4;
        padding-bottom: 5px;
      }

      .employee-table {
        .headers {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
          padding: 12px 0px;
          color: #0E283F;

          div {
            &:first-child {
              width: 25%;
            }

            &:nth-child(2) {
              width: 25%;
            }

            &:nth-child(3) {
              width: 25%;
            }

            &:last-child {
              width: 25%;
            }
          }
        }

        .records {
          .record-mobile {
            display: none;
          }

          .record {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            color: #0E283F;

            div {
              &:first-child {
                width: 25%;
              }
  
              &:nth-child(2) {
                width: 25%;
              }
  
              &:nth-child(3) {
                width: 25%;
              }
  
              &:last-child {
                width: 25%;
              }
            }
          }
        }
      }
    } 
  }

  .bottom-content {
    margin-top: 48px;

    .payroll {
      padding: 16px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.16);

      .title {
        color: #0E283F;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 0.5px solid #96A7C4;
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;

        .btn-blue {
          padding: 6px 12px;
          font-size: 20px;
          font-weight: 500;
        }
      }

      .payroll-table {
        .record {
          display: flex;
          justify-content: space-between;
          margin: 12px;
          background-color: rgba($color: #F0F6FF, $alpha: 0.6);

          .left {
            width: 50%;
            font-size: 18px;
            border-right: 1px solid #96A7C4;
            
            padding: 12px;
            padding-right: 1%;

            .field {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 3px 0px;
            }
          }

          .right {
            width: 49%; 
            font-size: 18px;

            .headers {
              display: flex;
              justify-content: space-between;
              padding-top: 15px;
              padding-bottom: 2px;
              font-weight: bold;

              div {
                width: 33%;
              }
            }

            .records {
              .value {
                display: flex;
                justify-content: space-between;
                
                div {
                  width: 33%;
                }
              }

              .value-mobile {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .payroll-page {
    .top-content {
      display: block;
      .check-date {
        width: 100%;
        margin-bottom: 48px;
      }

      .totals-by-employee {
        width: 100%;
      }
    }

    .bottom-content {
      .payroll {
        .records {
          .record {
            display: block;
            background-color: rgba($color: #F0F6FF, $alpha: 0.6);

            .left {
              width: 100%;
              border-right: 0;
              border-bottom: 1px solid #96A7C4;
            }

            .right {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .payroll-page {
    .bottom-content {
      .payroll {
        .payroll-table {
          .record {
            display: block;
            padding: 12px;

            .left {
              width: 100%;
              border-right: 0;
              border-bottom: 1px solid #96A7C4;
            }

            .right {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .payroll-page {
    .top-content {
      .check-date {
        
      }

      .totals-by-employee {
        .employee-table {
          .headers {
            display: none;
          }

          .records {
            .record {
              display: none;
            }

            .record-mobile {
              display: block;
              font-size: 18px;
              margin: 12px 0px;
              padding: 6px;
              background-color: rgba($color: #F0F6FF, $alpha: 0.6);

              .mobile-field {
                display: flex;
                justify-content: space-between;

                div {
                  &:first-child {
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }

    .bottom-content {
      .payroll {
        .payroll-table {
          .record {
            .left {
              
            }

            .right {
              .headers {
                display: none;
              }

              .records {
                padding: 12px 0px;
                .value {
                  display: none;
                }

                .value-mobile {
                  display: block;
                  .field {
                    display: flex;
                    justify-content: space-between;

                    div {
                      &:first-child {
                        font-weight: bold;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .payroll-page {
    .bottom-content {
      .payroll {
        .records {
          .record {
            display: block;
            background-color: rgba($color: #F0F6FF, $alpha: 0.6);

            .left {
              width: 100%;
              border-right: 0;
              border-bottom: 1px solid #96A7C4;

              .employee {
                width: 100%;
              }
  
              .date {
                width: 100%;
              }
  
              .sub-title {
                width: 100%;
                margin-top: 12px;
              }
  
              .customer {
                width: 100%;
                margin-top: 12px;
              }
  
              .gross {
                width: 100%;
                margin-top: 12px;
              }
  
              .owed {
                width: 100%;
                margin-top: 12px;
              }
  
              .total {
                width: 100%;
                margin-top: 12px;
              }
            }

            .right {
              width: 100%;
              display: block;

              .paid-on {
                display: flex;
                justify-content: space-between;
              }

              .paid-by {
                display: flex;
                justify-content: space-between;
              }

              .amount-paid {
                display: flex;
                justify-content: space-between;
              }
            }
          }
        }
      }
    }
  }
}