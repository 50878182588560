.expense-page {
  // padding-top: 48px;

  .title {
    padding-top: 0 !important;
  }

  .top-content {
    display: flex;
    justify-content: space-between;

    .check-date {
      height: fit-content;
      width: 23%;
      min-width: 315px;
      margin-right: 24px;
      padding: 16px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.16);
      display: flex;
      flex-direction: column;
      gap: 20px;

      .title {
        color: #0E283F;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 0.5px solid #96A7C4;
        padding-bottom: 5px;
      }

      .date-range {
        margin: 20px 0px;
        padding: 16px;
        background-color: rgba($color: #F0F6FF, $alpha: 0.6);
        border-radius: 10px;

        .form-input {
          margin-bottom: 2rem;
          margin-top: 1rem;
          font-size: 18px;
          text-align: left;
          position: relative;

          .form-label {
              font-weight: 500;
              color: #0E283F;
          }

          input {
              width: 100%;
              border: none;
              border-bottom: 1px solid #0E283F;
              line-height: 24px;
              padding: 6px 0px;
              background: transparent;
              color: #0E283F;
              height: 36px;

              &:focus {
                  outline: none;
              }
          }

          select {
              width: 100%;
              border: none;
              border-bottom: 1px solid #0E283F;
              line-height: 24px;
              padding: 6px 0px; 
              background: transparent;
              color: #0E283F;
              height: 36px;

              &:focus {
                  outline: none;
              }
          }

          .value-field {
              width: 100%;
              border: none;
              border-bottom: 1px solid #0E283F;
              line-height: 24px;
              padding: 6px 0px;
              background: transparent;
              color: #9BABC4;
              height: 36px;
          }

          svg {
              position: absolute;
              right: -3px;
              top: 33px;
          }
      }

        .label {
          font-size: 16px;
          color: #96A7C4;
        }

        .calendar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;

          .rdt {
            width: 100%;
            padding-left: 12px;

            input {
              border: none;
              border-bottom: 1px solid #000;
              border-radius: 0;
              margin-top: 0;
            }
          }

          .react-select {
            width: 100%;
            // margin-top: 5px;
            margin-bottom: 0;

            .react-select__control {
              border: none;
              border-bottom: 1px solid #000;
              border-radius: 0;

              .react-select__value-container {
                padding-left: 0 !important;
              }

              .react-select__indicators {
                padding-right: 0 !important;
              }

              .react-select__placeholder {
                font-size: 18px;
              }
            }
          }
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        div {
          padding: 6px 12px;
          width: 48%;
          font-size: 20px;
        }
      }
    }

    .totals-by-employee {
      width: 73%;
      padding: 16px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 20px 20px 20px rgba($color: #ABBBD4, $alpha: 0.16);

      .title {
        color: #0E283F;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 0.5px solid #96A7C4;
        padding-bottom: 5px;
      }

      .employee-table {
        .headers {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
          padding: 12px 0px;
          color: #0E283F;

          div {
            width: 16%;
          }
        }

        .records {
          .record {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            margin: 6px 0px;

            div {
              width: 16%;

              &:last-child {
                display: flex;
                justify-content: flex-start;

                div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #96A7C4;
                  color: #fff;
                  width: 26px;
                  height: 26px;
                  border-radius: 5px;
                  margin: 0px 6px;
                }
              }
            }

            svg {
              &:hover {
                cursor: pointer;
              }
            }
          }

          .record-collapse {
            margin: 24px 0px;

            .file-status-bar {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .file-remove {
                margin-left: 24px;
                color: red;
              }
            }
          }

          .record-mobile {
            display: none;
          }

          .record-collapse-mobile {
            display: none;
          }
        }
      }
    } 
  }
}

@media screen and (max-width: 1250px) {
  .expense-page {
    .top-content {
      display: block;
      .check-date {
        width: 100%;
        margin-bottom: 48px;
        flex-direction: row;
        .checkBox {
          width: 50%;
        }
      }

      .totals-by-employee {
        width: 100%;
      }
    }

    .bottom-content {
      .payroll {
        .payroll-table {
          .headers {
            display: none;
          }
          
          .records {
            .record {
              display: none;
            }
  
            .record-mobile {
              display: block;
              font-size: 18px;
              background-color: rgba($color: #F0F6FF, $alpha: 0.6);
              padding: 6px 12px;
              margin: 12px 0px;

              div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 6px 0px;

                span {
                  &:first-child {
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .expense-page {
    .top-content {
      .check-date {
        flex-direction: column;
        .checkBox {
          width: 100%;
        }
      }

      .totals-by-employee {
        .employee-table {
          .headers {
            display: none;
          }

          .records {
            .record {
              display: none;
            }

            .record-collapse {
              display: none !important;
            }

            .record-mobile {
              display: block;
              margin-bottom: 12px;
              background-color: rgba($color: #F0F6FF, $alpha: 0.6);
              padding: 6px;

              div {
                display: flex;
                justify-content: space-between;
                font-size: 18px;
                padding: 3px 0px;

                span {
                  &:first-child {
                    font-weight: bold;
                  }
                }

                &:last-child {
                  span {
                    &:last-child {
                      display: flex;
                      justify-content: space-around;

                      div {
                        background-color: #96A7C4;
                        margin-right: 6px;
                        color: #fff;
                        width: 26px;
                        height: 26px;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }
                    }
                  }
                }
              }
            }

            .record-collapse-mobile {
              display: block;
              margin-bottom: 12px;
              margin-top: -12px;
              background-color: rgba($color: #F0F6FF, $alpha: 0.6);
              padding: 6px;

              .file-status-bar {
                display: flex;
                
                .file-remove {
                  margin-left: 12px;
                  color: red;
                }
              }
            }
          }
        }
      }
    }

    .bottom-content {
      .payroll {
        .records {
         
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .expense-page {
    .bottom-content {
      .payroll {
        .records {
          
        }
      }
    }
  }
}